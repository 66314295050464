<template>
    <p align="center" class="text-h5 mb-2">Восстановить пароль:</p>

    <Form
        class="mt-4"
        @submit.prevent="onSubmit"
        ref="resetPassword"
    >

        <v-alert
            v-if="errorMessage && !successMessage"
            type="error"
            variant="outlined"
            icon="fas fa-exclamation"
            class="mb-4"
        >
            {{ errorMessage }}
        </v-alert>

        <v-alert
            v-if="!errorMessage && successMessage"
            type="success"
            variant="outlined"
            icon="fas fa-exclamation"
            class="mb-4"
        >
            {{ successMessage }}
        </v-alert>

        <Field
            v-if="!successMessage"
            v-model="email"
            name="email"
            :rules="validateEmail"
            v-slot="{ field, errors }"  
        >
            <v-text-field
                v-bind="field"
                label="E-mail"
                type="email"
                color="indigo-darken-3"
                variant="underlined"
                :error-messages="errors"
                required
                class="mt-2"
                :disabled="loading"
            />
        </Field>

        <v-btn
            v-if="!successMessage"
            block
            class="mt-2"
            color="indigo-darken-3"
            type="submit"
            @click.prevent="onSubmit"
            :loading="loading"
        >
            Сбросить пароль
        </v-btn>

    </Form>

    <v-btn :to="{ name: 'sign_in', query: fromPath }" variant="text" class="mt-2" block>
        Войти
    </v-btn>

</template>

<script>
import { Form, Field } from 'vee-validate';

export default {
    name: 'resetPassword',
    components: {
        Form,
        Field
    },
    data: () => ({
        email: '',
        errorMessage: '',
        successMessage: '',
        loading: false,
    }),
    methods: {
        onSubmit() {
            this.$refs.resetPassword.validate().then(data => {
                if(data.valid)
                    this.submit()
            })
        },
        submit() {

            this.loading = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/oauth/reset-password',
                data: {
                    email: this.email,
                }
            }).then(res => {

                this.errorMessage = ''

                if(res.data) {
                    if(res.data.status === 'success') {
                        this.successMessage = res.data.message
                    }else{
                        if(res.data.message)
                            this.errorMessage = res.data.message
                    }
                }else if(res.response && res.response.status == 422) {
                    this.errorMessage = 'Проверьте email'
                }

                this.loading = false
            })
        },
        validateEmail(value) {
            if (!value)
                return 'Введите E-mail'

            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

            if (!regex.test(value))
                return 'Некорректно введен E-mail'

            return true;
        }
    },
    computed: {
        fromPath() {
            if(this.$route.query.from)
                return { from: this.$route.query.from }

            return ''
        }
    }
}
</script>
