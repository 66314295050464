<template>
    <v-layout class="rounded rounded-md">

        <AppBar />

        <NavigationPanel />

        <v-main class="d-flex" style="height: 100vh;">
            <div class="app">
                
                <BreadcrumbsList />

                <div class="app__content">
                    <router-view/>
                </div>

            </div>
        </v-main>
        <v-footer 
            app
            height="40"
        >
            <div class="text-center w-100">
                © {{ new Date().getFullYear() }} - <a href="https://www.ecvols.ru" target="_blank" title="Ecvols"><strong>Ecvols</strong></a>
            </div>
        </v-footer>
    </v-layout>
</template>

<script>

import mqtt from "mqtt"

import AppBar from '@/components/AppBar'
import NavigationPanel from '@/components/NavigationPanel'
import BreadcrumbsList from '@/components/BreadcrumbsList'

export default {
    name: 'MainLayout',
    components: {
        AppBar,
        NavigationPanel,
        BreadcrumbsList,
    },
    data: () => ({
        connectingMqtt: false,
        retryTimes: 0,
        qosList: [0, 1, 2],
    }),
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.$store.dispatch('API', {
                method: 'POST',
                url: '/init'
            }).then(res => {
                if(res.data){
                    if(res.data.status === 'success') {

                        let data = res.data.data

                        this.$store.commit('Update', {
                            key: 'user',
                            value: data.user
                        })

                        if (data['mqtt']) {
                            let data_mqtt = data['mqtt']

                            let protocol = data_mqtt['ssl'] ? 'wss' : 'ws'

                            let connectUrl = `${protocol}://${data_mqtt['host']}:${data_mqtt['port']}${data_mqtt['endpoint']}`

                            const client = mqtt.connect(connectUrl, {
                                clean: true,
                                protocolId: 'MQTT',
                                connectTimeout: 30 * 1000, // ms
                                reconnectPeriod: 4000, // ms
                                clientId: "admin_" + Math.random().toString(16).substring(2, 8) + "_" + this.$store.state.user.id,
                                username: "a_" + data_mqtt['secret'],
                                password: this.$store.state.token,
                            })

                            this.$store.commit('Update', {
                                key: 'mqtt_client',
                                value: client
                            })

                            this.connectMqttServer()
                        }

                    }
                }
            })
        },
        connectMqttServer() {
            try {
                if (this.client.on) {
                    this.client.on('connect', () => {
                        this.connecting = false
                        console.log('Connection succeeded!')
                    })
                    this.client.on('reconnect', this.handleOnReConnect)
                    this.client.on('error', error => {
                        console.log('Connection failed', error)
                    })
                    
                }

            } catch (error) {
                this.connecting = false
                console.log('mqtt.connect error', error)
            }
        },
        initData() {
            this.retryTimes = 0
            this.connecting = false
        },
        handleOnReConnect() {
            this.retryTimes += 1

            if (this.retryTimes > 5) {
                try {
                    this.client.end()
                    this.initData()
                    console.log("Connection maxReconnectTimes limit, stop retry");
                } catch (error) {
                    console.log(error.toString());
                }
            }
        },
        destroyConnection() {
            try {
                this.client.end(false, () => {
                    this.initData()
                    console.log('Successfully disconnected!')
                })
            } catch (error) {
                console.log('Disconnect failed', error.toString())
            }
        }
    },
    computed: {
        client() {
            return this.$store.state.mqtt_client
        }
    },
    unmounted() {
        this.destroyConnection()
    },
}
</script>
