import axios from 'axios'
import router from "../router"

export default {
    API (context, data, method) {


        let headers = {
            'Accept': 'application/json'
        }

        if(context.state.token) {
            headers['Authorization'] = 'Bearer ' + context.state.token
        }

        const instance = axios.create({
            baseURL: context.state.baseURL,
            headers: headers
        })

        instance.interceptors.request.use(config => {
            context.commit('Update', {
                key: 'preloaderGlobal',
                value: true
            })
            return config
        })

        instance.interceptors.response.use(response => {
            context.commit('Update', {
                key: 'preloaderGlobal',
                value: false
            })

            return response
        }, error => {

            // const getParams = Object.keys(router.history.current.query).length

            context.commit('Update', {
                key: 'preloaderGlobal',
                value: false
            })

            if (error.response.status === 400) {
                context.commit('Update', {
                    key: 'errorServer',
                    value: {
                        status: true,
                        code: error.response.status
                    }
                })

                return error
            }
            
            if (error.response.status === 401) {
                context.dispatch('USER_LOGIN_OUT')
            }

            if (error.response.status === 403) {
                context.commit('Update', {
                    key: 'errorServer',
                    value: {
                        status: true,
                        code: error.response.status
                    }
                })

                return error
            }

            if (error.response.status === 404) {
                context.commit('Update', {
                    key: 'errorServer',
                    value: {
                        status: true,
                        code: error.response.status
                    }
                })

                return error
            }

            if (error.response.status === 500) {
                context.commit('Update', {
                    key: 'errorServer',
                    value: {
                        status: true,
                        code: error.response.status
                    }
                })

                return error
            }

            if (error.response.status === 502) {
                context.commit('Update', {
                    key: 'errorServer',
                    value: {
                        status: true,
                        code: error.response.status
                    }
                })

                return error
            }

            if (error.response.status === 503) {
                context.commit('Update', {
                    key: 'errorServer',
                    value: {
                        status: true,
                        code: error.response.status
                    }
                })

                return error
            }
            
            return error
        })

        return instance(Object.assign({ method: method }, data))
    },
    LOGOUT(context) {
        context.dispatch('API', {
            url: '/oauth/sign-out',
            data: {},
            method: 'POST'
        }).then(res => {

            if(res.status)
                context.dispatch('USER_LOGIN_OUT')

            return true
        })

        return false
    },
    USER_LOGIN_OUT(context) {
        context.commit('Update', {
            key: 'token',
            value: ''
        })

        context.commit('Update', {
            key: 'user',
            value: {}
        })

        router.push({ name: 'auth' })
    },
}