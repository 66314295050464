<template>
    <SensorTemperature v-if="sensor.type === 0" :sensor="sensor">
        <template #actionHeader>
            <v-btn
                icon
                variant="plain"
                density="compact"
                @click="setting(sensor.id)"
            >
                <v-icon size="15">fa fa-cog</v-icon>
            </v-btn>
        </template>
    </SensorTemperature>

    <SensorWaterLeak v-else-if="sensor.type === 1" :sensor="sensor">
        <template #actionHeader>
            <v-btn
                icon
                variant="plain"
                density="compact"
                @click="setting(sensor.id)"
            >
                <v-icon size="15">fa fa-cog</v-icon>
            </v-btn>
        </template>
    </SensorWaterLeak>

    <SensorPressure v-else-if="sensor.type === 2" :sensor="sensor">
        <template #actionHeader>
            <v-btn
                icon
                variant="plain"
                density="compact"
                @click="setting(sensor.id)"
            >
                <v-icon size="15">fa fa-cog</v-icon>
            </v-btn>
        </template>
    </SensorPressure>

    <SensorFlowRelay v-else-if="sensor.type === 3" :sensor="sensor">
        <template #actionHeader>
            <v-btn
                icon
                variant="plain"
                density="compact"
                @click="setting(sensor.id)"
            >
                <v-icon size="15">fa fa-cog</v-icon>
            </v-btn>
        </template>
    </SensorFlowRelay>

    <SensorWeight v-else-if="sensor.type === 4" :sensor="sensor">
        <template #actionHeader>
            <v-btn
                icon
                variant="plain"
                density="compact"
                @click="setting(sensor.id)"
            >
                <v-icon size="15">fa fa-cog</v-icon>
            </v-btn>
        </template>
    </SensorWeight>

    <SettingSensorPopup :sensor_id="edit_sensor_id" @close="closeEdit" @update="upSensor"/>
</template>
<script>

import SensorTemperature from './sensor/SensorTemperature'
import SensorWaterLeak from './sensor/SensorWaterLeak'
import SensorPressure from './sensor/SensorPressure'
import SensorFlowRelay from './sensor/SensorFlowRelay'
import SensorWeight from './sensor/SensorWeight'
import SettingSensorPopup from './sensor/SettingSensorPopup'

export default {
    name: 'SensorStats',
    emits: ['editSensor'],
    props: {
        sensor: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    components: {
        SensorTemperature,
        SensorWaterLeak,
        SensorPressure,
        SensorFlowRelay,
        SensorWeight,
        SettingSensorPopup
    },
    data: () => ({
        edit_sensor_id: null
    }),
    mounted () {
    },
    watch: {
    },
    methods: {
        setting(id) {
            this.edit_sensor_id = id
        },
        closeEdit() {
            this.edit_sensor_id = null
        },
        upSensor(data) {
            this.$emit('editSensor', data)
        }
    },
    computed: {
    },
    created() {   
    },
    unmounted() {
    }
}
</script>