<template>
    <p align="center" class="text-h5 mb-2">Войдите, что бы продолжить:</p>

    <Form
        class="mt-4"
        @submit.prevent="onSubmit"
        ref="signIn"
    >

        <v-alert
            v-if="errorMessage"
            type="error"
            variant="outlined"
            icon="fas fa-exclamation"
            class="mb-4"
        >
            {{ errorMessage }}
        </v-alert>

        <Field
            v-model="email"
            name="email"
            :rules="validateEmail"
            v-slot="{ field, errors }"  
        >
            <v-text-field
                v-bind="field"
                label="E-mail"
                type="email"
                color="indigo-darken-3"
                variant="underlined"
                :error-messages="errors"
                required
                class="mt-2"
                :disabled="loading"
            />
        </Field>

        <Field
            v-model="password"
            name="password"
            :rules="validatePassword"
            v-slot="{ field, errors }"
        >
            <v-text-field
                v-bind="field"
                label="Пароль"
                type="password"
                color="indigo-darken-3"
                variant="underlined"
                :error-messages="errors"
                required
                class="mt-2"
                :disabled="loading"
            />
        </Field>

        <v-switch
            v-model="rememberMe"
            label="Запомнить меня?"
            color="indigo-darken-3"
            value="1"
            hide-details
        ></v-switch>

        <v-btn
            block
            class="mt-2"
            color="indigo-darken-3"
            type="submit"
            @click.prevent="onSubmit"
            :loading="loading"
        >
            Войти
        </v-btn>

    </Form>

    <v-btn :to="{ name: 'reset_password', query: fromPath }" variant="text" class="mt-2" block>
        Забыли пароль?
    </v-btn>

</template>

<script>
import { Form, Field } from 'vee-validate';

export default {
    name: 'SignIn',
    components: {
        Form,
        Field
    },
    data: () => ({
        email: '',
        password: '',
        rememberMe: 0,
        errorMessage: '',
        loading: false,
    }),
    methods: {
        onSubmit() {
            this.$refs.signIn.validate().then(data => {
                if(data.valid)
                    this.submit()
            })
        },
        submit() {

            this.loading = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/oauth/sign-in',
                data: {
                    email: this.email,
                    password: this.password,
                    remember_me: this.rememberMe
                }
            }).then(res => {

                this.errorMessage = ''

                if(res.data) {
                    if(res.data.status === 'success') {
                        this.$store.commit('Update', {
                            key: 'token',
                            value: res.data.data.token
                        })

                        if(this.$route.query.from)
                            this.$router.push({ path: this.$route.query.from })
                        else
                            this.$router.push({ name: 'main' })

                    }else{
                        if(res.data.message)
                            this.errorMessage = res.data.message
                    }
                }

                this.loading = false
            })
        },
        validateEmail(value) {
            if (!value)
                return 'Введите E-mail'

            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

            if (!regex.test(value))
                return 'Некорректно введен E-mail'

            return true;
        },
        validatePassword(value) {
            if(this.email && !value)
                return 'Введите пароль'

            return true
        }
    },
    computed: {
        fromPath() {
            if(this.$route.query.from)
                return { from: this.$route.query.from }

            return ''
        }
    }
}
</script>
