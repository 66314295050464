<template>
    <div>
        DeliveryPage
    </div>
</template>
<script>
export default {
    name: 'DeliveryPage',
    data: () => ({
    })
}
</script>