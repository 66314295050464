<template>
    <h1 class="text-h4 mb-1">{{ titleHeading }}</h1>

    <div class="app-catalog__category">
        <Form
            @submit.prevent="submit"
            ref="createOrUpdateCategory"
            :validation-schema="schema"
        >
            <v-row>
                <v-col sm="12" md="8">


                    <Field
                        v-model="fields.name"
                        name="name"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="ФИО"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <Field
                        v-model="fields.phone"
                        name="phone"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Телефон"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <Field
                        v-model="fields.email"
                        name="email"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="E-mail"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <Field
                        v-model="fields.data_id"
                        name="data_id"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="ID на сайте Экволс"
                            color="indigo-darken-3"
                            variant="underlined"
                            :disabled="loading"
                            :error-messages="errors"
                        />
                    </Field>

                </v-col>            

                <v-col sm="12">
                    <div class="d-flex justify-space-between">
                        <v-btn
                            @click="$router.go(-1)"
                        >
                            Назад
                        </v-btn>
                        <!-- <v-btn
                            color="success"
                            :loading="loading"
                            @click="submit"
                        >
                            Сохранить
                        </v-btn> -->
                    </div>
                </v-col>

            </v-row>
        </Form>
    </div>
</template>
<script>

import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'StockEditPage',
    components: {
        Form,
        Field
    },
    data: () => ({
        title: '',
        id: null,
        file: null,
        fields: {
            'name': null,
            'phone': null,
            'email': null,
            'data_id': null,
        },
        loading: false,
    }),
    mounted () {
        if(this.$route.params.id) this.id = this.$route.params.id
    },
    watch: {
        id() {
            this.init()
        }
    },
    methods: {
        init() {
            this.get()
        },
        get() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/user/' + this.id + '/edit',
            }).then(res => {

                if(res.data.status === 'success') {

                    if(res.data.data.name) this.setTitle(res.data.data.name)

                    this.setField(res.data.data)

                }

                this.loading = false

            })
        },
        setTitle(title = '') {
            if(title) {
                this.$store.commit('Update', {
                    key: 'titlePage',
                    value: title
                })

                this.title = title
            }
        },
        setField(fields) {
            for (let key in fields) {
                if(this.fields[key] !== undefined) {
                    if (key == 'status')
                        this.fields[key] = fields[key] == 1 ? true : false
                    else
                        this.fields[key] = fields[key]
                }
            }
        },
        submit() {
            this.$refs.createOrUpdateCategory.validate().then(data => {
                if(data.valid) {
                    if(this.id) this.update()
                    else this.create()
                }
            })
            
        },
        create() {
            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key !== 'image') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : (value == 0 ? 0 : ''))
                    }
                }
            }


            this.$store.dispatch('API', {
                method: 'POST',
                url: '/user/create',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        if(res.data.data.title) this.setTitle(res.data.data.title)

                        this.setField(res.data.data)

                        if(res.data.data.id) this.$router.push({ name: 'user_edit', params: { id: res.data.data.id }})
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateCategory.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        update() {

            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key !== 'image') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : (value == 0 ? 0 : ''))
                    }
                }
            }


            this.$store.dispatch('API', {
                method: 'POST',
                url: '/user/' + this.id + '/update',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {

                        if(res.data.data.title) this.setTitle(res.data.data.title)

                        this.setField(res.data.data)

                        this.file = null
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateCategory.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        }
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
        schema() {
            return yup.object({
                name: yup.string().required().min(2).max(255),
                phone: yup.string().required().min(2).max(255),
                email: yup.string().email().required().min(2).max(255),
                data_id: yup.string().max(255).nullable(),
            })
        }
    }
}
</script>