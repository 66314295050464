<template>
    <Teleport to="body">
        <v-dialog
            v-model="dialog"
            width="280px"
        >
            <v-card>
                <v-card-title>
                    {{ title }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="body" v-html="body"></v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        color="warning"
                        variant="text"
                        @click="close"
                        :disabled="loading"
                    >
                        Отмена
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        variant="text"
                        @click="submit"
                        :loading="loading"
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </Teleport>
</template>
<script>
export default {
    name: 'RemovePopup',
    emits: ['action', 'close'],
    props: {
        title: {
            type: String,
            default: 'Удалить'
        },
        body: {
            type: String,
            default: null
        },
        open: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        dialog: false,
    }),
    watch: {
        open(val) {
            this.dialog = val
        },
        dialog(val) {
            if(this.open && !val) this.close()
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        submit() {
            this.$emit('action', this.data)
        }
    }
}
</script>