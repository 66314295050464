<template>
    <div v-if="loading" class="loading">
        <v-progress-circular
            color="indigo-darken-3"
            indeterminate
        ></v-progress-circular>
    </div>
    <v-chart v-else :option="option" />
</template>
<script>

import { use } from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"
import { LineChart } from "echarts/charts"
import {
    TooltipComponent,
    GridComponent,
    VisualMapComponent
} from "echarts/components"
import VChart, { THEME_KEY } from "vue-echarts"

use([
    CanvasRenderer,
    LineChart,
    TooltipComponent,
    GridComponent,
    VisualMapComponent
])

export default {
    name: 'SensorStatTemperature',
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    components: {
        VChart
    },
    provide: {
        [THEME_KEY]: "light"
    },
    data: () => ({
        loading: true,
        option: null,
        items: null,
        reset: null
    }),
    mounted () {
        this.init()
    },
    watch: {
        tempValue() {
            this.parseData()
        }
    },
    methods: {
        init() {
            this.loading = true

            this.get()

            this.reset = setInterval(() => {
                this.get()
            }, 60000)
        },
        get() {
            this.$store.dispatch('API', {
                method: 'GET',
                url: '/device/' + this.$route.params.id + '/stats/sensor/' + this.id
            }).then(res => {

                if(res.data){
                    if(res.data.status === 'success') {

                        this.items = res.data.data
                        this.parseData()

                    }
                }

                this.loading = false
            })
        },
        parseData() {
            let keys = []
            let values = []

            let items = Object.fromEntries(Object.entries(this.items).sort())

            for(let key in items) {
                keys.push(this.$moment(key).format('dddd, DD.MM HH:mm') + ' - ' + this.$moment(key).add(1, 'hours').format('HH:mm'))
                values.push(this.value(items[key]))
            }

            this.setChart(keys, values)
        },
        value(val = 273.15) {
            let temp = val - 273.15

            if(this.tempValue === 1) {
                temp = temp * 1.8 + 32
            }

            return Math.round(temp)
        },
        setChart(keys = [], values = []) {
            this.option = {
                grid: {
                    top: '0%',
                    left: '0%',
                    right: '0%',
                    bottom: '10%',
                    containLabel: false
                },
                xAxis: {
                    data: keys,
                    axisLine: {
                        show: this.tempValue === 0 ? true : false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                yAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                tooltip: {
                    trigger: "item",
                    formatter: '{b}<br /><div style="text-align: center;"><b>{c}</b></div>'
                },
                series: [
                    {
                        data: values,
                        type: 'line',
                        smooth: true,
                        layout: 'none',
                        showSymbol: true
                    }
                ],
                visualMap: [
                    {
                        show: false,
                        type: 'continuous',
                        seriesIndex: 0,
                        min: this.tempValue === 0 ? -25 : 32,
                        max: this.tempValue === 0 ? 25 : 77,
                        inRange: {
                            color: ['#3196FA', '#EF4864']
                        }
                    }
                ]
            }
        }
    },
    computed: {
        item() {
            return this.sensor
        },
        tempValue() {
            return this.$store.state.temperatureValue
        },
        tempLabel() {
            return this.tempValue === 0 ? '°C' : '°F'
        },
    },
    created() {},
    unmounted() {
        clearInterval(this.reset)
        this.reset = null
    },
}
</script>