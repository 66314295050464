import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labsComponents from 'vuetify/labs/components'
import 'vuetify/styles'
import '@fortawesome/fontawesome-free/css/all.css'

export default createVuetify({
    iconfont: 'fa',
    components: {
        ...components,
        ...labsComponents
    },
    directives,
})