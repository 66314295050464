<template>
    <v-dialog
        v-model="open"
        persistent
        max-width="600"
    >
        <v-card>
            <v-card-title>
                Пользователи
            </v-card-title>

            <v-row class="pl-3 pr-3">
                <v-col cols="8">
                    <v-text-field
                        v-model="filter.search"
                        clearable
                        label="Поиск"
                        prepend-icon="fa fa-search"
                        variant="outlined"
                        density="compact"
                        hide-details
                        clear-icon="fa fa-close"
                        :loading="getLoading ? 'indigo-darken-3' : false"
                        @keyup="setFilter"
                        @click:clear="clearFilter"
                    ></v-text-field>
                </v-col>
                <v-col 
                    cols="4"
                    class="pr-2"
                >
                    <v-select
                        v-model="filter.type"
                        label="Поиск по"
                        :items="searchType"
                        item-title="name"
                        item-value="value"
                        variant="outlined"
                        hide-details
                        density="compact"
                        menu-icon="fa fa-angle-down"
                        :disabled="getLoading"
                        @update:modelValue="setNewFilter"
                    ></v-select>
                </v-col>
            </v-row>

            <v-skeleton-loader
                :loading="getLoading"
                type="list-item@3"
                class="w-100 d-block"
            >
                <v-card-item v-if="!filter.search">
                    <v-list
                        lines="three"
                    >
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            :value="item"
                            color="primary"
                            :link="false"
                        >

                            <template v-slot:prepend>
                                <v-avatar color="text-white">
                                    {{ item.id }}
                                </v-avatar>
                            </template>

                            <v-list-item-title>
                                {{ item.name }} 
                            </v-list-item-title>

                            <v-list-item-subtitle>
                                {{ item.phone }}
                                <br />
                                {{ item.email }}
                            </v-list-item-subtitle>

                            <template v-slot:append>

                                <v-btn
                                    size="small"
                                    icon
                                    variant="plain"
                                    :to="{ name: 'user_edit', params: { id: item.id }}"
                                    @click="setTitle(item.name)"
                                >
                                    <v-icon size="14">fa fa-eye</v-icon>
                                </v-btn>

                                <!-- <v-btn
                                    :color="!item.device_status ? 'error' : 'success'"
                                    size="small"
                                    icon
                                    variant="plain"
                                    @click="updateStatus(i, item.id)"
                                    :loading="this.statusLoading[i]"
                                    :disabled="!item.device_status"
                                >
                                    <v-icon size="14">fa fa-power-off</v-icon>
                                </v-btn> -->

                                <v-btn
                                    color="error"
                                    size="small"
                                    icon
                                    variant="plain"
                                    @click="remove(i, item)"
                                    :loading="removeLoading[i]"
                                >
                                    <v-icon size="14">fa fa-trash</v-icon>
                                </v-btn>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-card-item>

                <v-card-item v-else>
                    <v-list
                        lines="three"
                    >
                        <v-list-item
                            v-for="(item, i) in searchItems"
                            :key="i"
                            :value="item"
                            color="primary"
                            :link="false"
                        >

                            <template v-slot:prepend>
                                <v-avatar color="text-white">
                                    {{ item.id }}
                                </v-avatar>
                            </template>

                            <v-list-item-title>
                                {{ item.name }} 
                            </v-list-item-title>

                            <v-list-item-subtitle>
                                {{ item.phone }}
                                <br />
                                {{ item.email }}
                            </v-list-item-subtitle>

                            <template v-slot:append>

                                <v-btn
                                    size="small"
                                    icon
                                    variant="plain"
                                    :to="{ name: 'user_edit', params: { id: item.id }}"
                                    @click="setTitle(item.name)"
                                >
                                    <v-icon size="14">fa fa-eye</v-icon>
                                </v-btn>

                                <v-btn
                                    color="success"
                                    size="small"
                                    icon
                                    variant="plain"
                                    @click="addUser(item.id)"
                                    :loading="addLoading[item.id]"
                                >
                                    <v-icon size="14">fa fa-plus</v-icon>
                                </v-btn>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-card-item>
            </v-skeleton-loader>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-3"
                    text="Закрыть"
                    @click="open = false"
                ></v-btn>
            </template>
        </v-card>
        <RemovePopup
            :data="removeData"
            :open="openRemove"
            :title="titleRemove"
            :body="bodyRemove"
            @close="closeRemove"
            @action="removeItem"
            :loading="loadingRemove"
        />
    </v-dialog>
</template>
<script>

import RemovePopup from '@/components/popus/RemovePopup'

export default {
    name: 'UserPopup',
    emits: ['close'],
    props: {
        device_id: {
            type: Number,
            default: null
        }
    },
    components: {
        RemovePopup
    },
    data: () => ({
        open: false,
        loading: false,
        getLoading: false,
        filter: {
            type: 'all',
            search: '',
        },
        searchType: [
            {
                'value': 'all',
                'name': 'Везде'
            },
            {
                'value': 'id',
                'name': 'ID'
            },
            {
                'value': 'name',
                'name': 'ФИО'
            },
            {
                'value': 'phone',
                'name': 'Телефон'
            },
            {
                'value': 'email',
                'name': 'E-mail'
            }
        ],
        timerSearch: null,
        searchItems: [],
        items: [],
        addLoading: [],
        // statusLoading: [],
        removeLoading: [],

        openRemove: false,
        removeData: {},
        loadingRemove: false,
        titleRemove: '',
        bodyRemove: ''
    }),
    mounted () {
    },
    watch: {
        device_id(value) {
            if(value) {
                this.open = true
            }else{
                this.open = false
            }
        },
        open(status) {
            if(!status) {
                this.$emit('close')
            }else{
                this.init()
            }
        },
    },
    methods: {
        init() {

            this.filter = {
                type: 'all',
                search: '',
            }

            clearTimeout(this.timerSearch)

            this.searchItems = []

            this.getLoading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/device/' + this.device_id + '/user'
            }).then(res => {

                if(res.data){
                    if(res.data.status === 'success') {
                        this.items = res.data.data
                    }
                }

                this.getLoading = false
            })
        },
        setTitle(title = '') {
            if(title) {
                this.$store.commit('Update', {
                    key: 'titlePage',
                    value: title
                })
            }
        },
        remove(i, item) {

            this.removeData = {
                key: i,
                item: item
            }

            this.openRemove = true
            this.titleRemove = 'Отсоеденить пользователя #' + item.id
            this.bodyRemove = 'Вы уверены, что хотите отключить пользователя <b>' + item.name + '</b> от устройства?'

        },
        closeRemove() {
            this.openRemove = false

        },
        removeItem(data) {

            this.removeLoading[data.key] = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/device/' + this.device_id + '/user/remove',
                data: {
                    user_id: data.item.id
                }
            }).then(res => {
                if(res.data){
                    if(res.data.status === 'success') {
                        this.items = res.data.data
                    }
                }

                delete this.removeLoading[data.key]

                this.openRemove = false

            })
        },
        // updateStatus(index, user_id) {

        //     this.statusLoading[index] = true

        //     this.$store.dispatch('API', {
        //         method: 'POST',
        //         url: '/device/' + this.device_id + '/user/status-update',
        //         data: {
        //             user_id: user_id
        //         }
        //     }).then(res => {

        //         if(res.data){
        //             if(res.data.status === 'success') {
        //                 this.items = res.data.data
        //             }
        //         }

        //         delete this.statusLoading[index]
        //     })
        // },
        addUser(user_id) {
            this.addLoading[user_id] = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/device/' + this.device_id + '/user/add',
                data: {
                    user_id: user_id
                }
            }).then(res => {

                if(res.data){
                    if(res.data.status === 'success') {
                        this.items = res.data.data
                        this.clearFilter()
                    }
                }

                delete this.addLoading[user_id]
            })
        },
        setFilter() {

            clearTimeout(this.timerSearch)

            this.getLoading = false

            this.timerSearch = setTimeout(() => {

                this.getSearch()

                this.timerSearch = null
            }, 500)
        },
        getSearch() {
            if(this.filter.search) {
                this.getLoading = true

                this.$store.dispatch('API', {
                    method: 'GET',
                    url: '/device/' + this.device_id + '/user/search',
                    params: this.filter
                }).then(res => {

                    if(res.data){
                        if(res.data.status === 'success') {
                            this.searchItems = res.data.data
                        }
                    }

                    this.getLoading = false
                })
            }else{
                this.clearFilter()
            }
        },
        setNewFilter() {
            this.getSearch()
        },
        clearFilter() {
            this.filter = {
                type: 'all',
                search: '',
            }

            clearTimeout(this.timerSearch)

            this.searchItems = []

            this.getLoading = false
        }
    },
    computed: {
    },
    created() {   
    },
    unmounted() {
        this.open = false
    }
}
</script>