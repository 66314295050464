export default {
    baseURL: process.env.VUE_APP_URL_SERVER,
    token: '',
    status: false,
    preloader: false,
    preloaderGlobal: false,

    user: {},

    snackbars: {
        status: false,
        color: '',
        text: '',
        timeout: 3000
    },

    permissions: [],
    titlePage: '',
    breadcrumbsItems: [],

    itemsLimit: 20,
    categoryItems: [],

    // Errors
    errorServer: {
        status: false,
        code: ''
    },

    temperatureValue: 0,
    pressureValue: 0,

    mqtt_client: null,

    device_sensors: null
}