<template>
    <div class="app-sensor water-leak">
        <div class="app-sensor__head">
            <div class="app-sensor__title">{{ item.name }}</div>
            <div class="app-sensor__action">

                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            variant="plain"
                            v-bind="props"
                            density="compact"
                        >
                            {{ pressureLabel }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item :active="pressureValue === 0" @click="setPressure(0)">
                            <v-list-item-title>МПа</v-list-item-title>
                        </v-list-item>
                        <v-list-item :active="pressureValue === 1" @click="setPressure(1)">
                            <v-list-item-title>бар</v-list-item-title>
                        </v-list-item>
                        <v-list-item :active="pressureValue === 2" @click="setPressure(2)">
                            <v-list-item-title>psi</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <slot name="actionHeader" />
            </div>
        </div>
        <div class="app-sensor__body">
            <div class="app-sensor__description font-weight-light">
                {{ item.description }}
            </div>
            <div class="app-sensor__value">
                {{ value }} <span>{{ pressureLabel }}</span>
            </div>
        </div>
        <div class="app-sensor__chart">
            <SensorStatPressure :value="valueBar"/>
        </div>
    </div>
</template>
<script>
import SensorStatPressure from './SensorStatPressure'

export default {
    name: 'SensorPressure',
    props: {
        sensor: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    components: {
        SensorStatPressure
    },
    data: () => ({

    }),
    mounted () {
    },
    watch: {
    },
    methods: {
        setPressure(val = 0) {
            this.$store.commit('Update', {
                key: 'pressureValue',
                value: val
            })
        }
    },
    computed: {
        item() {
            return this.sensor
        },
        pressureValue() {
            return this.$store.state.pressureValue
        },
        pressureLabel() {
            if (this.pressureValue === 1) return 'бар'
            else if(this.pressureValue === 2) return 'psi'

            return 'МПа'
        },
        valueBar() {
            return (this.item.value * 10).toFixed(1)
        },
        value() {
            let val = this.item.value

            if(this.pressureValue === 1) {
                return this.valueBar
            }else if (this.pressureValue === 2) {
                return Math.round(val * 145)
            }

            return val.toFixed(2)
        },
    },
    created() {   
    },
    unmounted() {
    }
}
</script>