<template>
    <ControlRelay v-if="control.type === 0" :control="control" @update="upControl">
        <template #actionHeader>
            <v-btn
                icon
                variant="plain"
                density="compact"
                @click="setting(control.id)"
            >
                <v-icon size="15">fa fa-cog</v-icon>
            </v-btn>
        </template>
    </ControlRelay>

    <SettingControlPopup :control_id="edit_control_id" @close="closeEdit" @update="upControl"/>
</template>
<script>
import ControlRelay from './control/ControlRelay'
import SettingControlPopup from './control/SettingControlPopup'

export default {
    name: 'ControlStats',
    emits: ['update'],
    props: {
        control: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    components: {
        ControlRelay,
        SettingControlPopup
    },
    data: () => ({
        edit_control_id: null
    }),
    mounted () {
    },
    watch: {
    },
    methods: {
        setting(id) {
            this.edit_control_id = id
        },
        upControl(item) {
            this.$emit('update', item)
        },
        closeEdit() {
            this.edit_control_id = null
        }
    },
    computed: {
    },
    created() {   
    },
    unmounted() {
    }
}
</script>