<template>
    <v-dialog
        v-model="open"
        persistent
        max-width="600"
    >
        <v-card
        >
            <v-card-title>
                Подключиться к новой сети
            </v-card-title>

            <v-card-item>
                <Form
                    @submit.prevent="submit"
                    ref="updateForm"
                    :validation-schema="schema"
                >

                    <Field
                        v-model="ssid"
                        name="ssid"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Название сети"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        />
                    </Field>

                    <Field
                        v-model="password"
                        name="password"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Пароль"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        />
                    </Field>
                </Form>
            </v-card-item>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-3"
                    text="Закрыть"
                    @click="open = false"
                ></v-btn>
                <v-btn
                    color="success"
                    text="Сохранить"
                    @click="submit"
                    :loading="loading"
                ></v-btn>
            </template>
        </v-card>
    </v-dialog>
</template>
<script>
import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'UpdateWifiPopup',
    emits: ['close'],
    props: {
        device_id: {
            type: Number,
            default: null
        }
    },
    components: {
        Form,
        Field
    },
    data: () => ({
        open: false,
        ssid: null,
        password: null,
        loading: false,
    }),
    mounted () {
    },
    watch: {
        device_id(value) {
            if(value) {
                this.open = true
            }else{
                this.open = false
            }
        },
        open(status) {
            if(!status) {
                this.$emit('close')
                this.ssid = null
                this.password = null
                this.$refs.updateForm.resetForm()
            }
        }
    },
    methods: {
        submit() {
            this.$refs.updateForm.validate().then(data => {
                if(data.valid) {

                    this.loading = true

                    this.$store.dispatch('API', {
                        method: 'POST',
                        url: '/device/' + this.device_id + '/wifi-connect',
                        data: {
                            ssid: this.ssid,
                            password: this.password
                        }
                    }).then(res => {
                        if(res.data) {
                            if(res.data.status === 'success') {
                                this.open = false
                            }
                        } else if(res.response && res.response.status == 422) {
                            if(res.response.data.errors) {
                                this.$refs.updateForm.setErrors(res.response.data.errors)
                            }
                        }

                        this.loading = false
                    })
                }
            })
        }
    },
    computed: {
        schema() {
            return yup.object({
                ssid: yup.string().required().min(1).max(32),
                password: yup.string().required().min(8).max(63),
            })
        },
    },
    created() {   
    },
    unmounted() {
        this.open = false
    }
}
</script>