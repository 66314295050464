<template>
    <v-dialog
        v-model="openDialog"
        persistent
        width="600px"
    >
        <v-card>
            <v-card-title class="text-h5">
                Настройки {{ title }}
            </v-card-title>
            <v-card-text>

                <h5>Модель устройства:</h5>
                <v-code class="code" v-if="data.model">
                    {{data.model.name}} ({{data.model.name_device}} - {{data.model.type}})
                </v-code>
                <br />

                <h5>ID устройства:</h5>
                <v-code class="code">
                    {{data.device_id}}
                </v-code>
                <br />

                <h5>ID внутрений:</h5>
                <v-code class="code">
                    {{data.machine_id}}
                </v-code>
                <br />

                <h5>Токен:</h5>
                <v-code class="code">
                    {{data.token}}
                </v-code>
                <br />

                <h5>sensors.json:</h5>
                <v-code class="code">
                    {{data.sensors}}
                </v-code>
                <br />

                <h5>Пароль от Wi-Fi (начальная настройка):</h5>
                <v-code class="code">
                    {{data.ap_wifi_password}}
                </v-code>
                <br />

                <h5>Прошивка:</h5>
                <v-code class="code" v-if="data.firmware">
                    {{data.firmware.name}} (v.{{data.firmware.version}})
                </v-code>

                <v-dialog max-width="300" v-if="data.last_firmware">
                    <template v-slot:activator="{ props: activatorProps }">
                        <v-btn
                            v-bind="activatorProps"
                            color="success"
                            :disabled="loading"
                            class="mt-2 mb-2"
                            density="compact"
                        >Обновить до v. {{data.last_firmware.version}}</v-btn>
                    </template>

                    <template v-slot:default="{ isActive }">

                        <v-card title="Обновить устройство">
                            <v-card-text>
                                Вы уверены, что хотите обновить версию на устройстве {{data.name}} до {{data.last_firmware.name}} (v.{{data.last_firmware.version}})?
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn
                                    text="Отмена"
                                    @click="isActive.value = false"
                                ></v-btn>

                                <v-btn
                                    color="warning"
                                    text="Обновить"
                                    @click="isActive.value = false; updateFirmware(data.last_firmware.id)"
                                ></v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                
                <Form
                    @submit.prevent="submit"
                    ref="update"
                    :validation-schema="schema"
                >
                    <h3>Основные</h3>

                    <Field
                        v-model="value.name"
                        name="name"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Название"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <Field
                        v-model="value.description"
                        name="description"
                        v-slot="{ field, errors }"  
                    >
                        <v-textarea
                            v-bind="field"
                            :model-value="field.value"
                            label="Описание"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                            row-height="5"
                            rows="1"
                            auto-grow
                        />
                    </Field>

                    <h3>Пользователь</h3>

                    <Field
                        v-model="value.device_user"
                        name="device_user"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Пользователь"
                            color="indigo-darken-3"
                            variant="underlined"
                            :hint="`Пользователь по-умолчанию: ` + data.default_device_user"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <Field
                        v-model="value.device_password"
                        name="device_password"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Пароль"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :hint="`Пароль по-умолчанию: ` + data.default_device_password"
                            required
                            :disabled="loading"
                            append-icon="fas fa-rotate-right"
                            @click:append="generateValue('device_password', 8)"
                        />
                    </Field>

                    <v-btn
                        density="compact"
                        @click="setUserAnPasswordDefault"
                        :disabled="loading"
                    >
                        Установить по-умолчанию
                    </v-btn>

                </Form>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    variant="text"
                    @click="close()"
                >
                    Отмена
                </v-btn>
                <v-btn
                    color="green-darken-1"
                    variant="text"
                    :loading="loading"
                    @click="submit"
                >
                    Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

const fields = {
    'name': null,
    'description': null,
    'device_user': null,
    'device_password': null,
}

export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number,
            default: null
        },
        item: {
            type: Object,
            default: () => {
                return null
            }
        },
    },
    emits: ['close'],
    components: {
        Form,
        Field
    },
    name: 'EditDevicePopup',
    data: () => ({
        data: {},
        value: {},
        openDialog: false,
        loading: false,
        updateSuccess: ''
    }),
    watch: {
        open(value) {
            this.openDialog = value

            this.value = fields

            if(value) {
                this.init()
            }else{
                this.$refs.update.resetForm()
            }
        }
    },
    methods: {
        init() {
            this.loading = true

            if(this.item && this.item.id) {
                this.$store.dispatch('API', {
                    method: 'GET',
                    url: '/device/' + this.item.id + '/setting',
                    params: {}
                }).then(res => {

                    if(res.data){
                        if(res.data.status === 'success') {
                            this.data = res.data.data

                            this.value = {
                                'name': res.data.data.name,
                                'description': res.data.data.description,
                                'device_user': res.data.data.device_user,
                                'device_password': null,
                            }
                        }
                    }

                    this.loading = false

                    this.$store.commit('Update', {
                        key: 'preloader',
                        value: false
                    })
                })
            }
        },
        close(item = null) {
            this.$emit('close', {
                index: this.index,
                item: item,
            })
        },
        submit() {
            this.$refs.update.validate().then(data => {
                if(data.valid) {
                    this.loading = true

                    let data = new FormData()

                    for (let [key, value] of Object.entries(this.value)) {

                        if(value === true || value === false) {
                            data.append(key, value ? 1 : 0)
                        }else{
                            data.append(key, value ? value : (value == 0 ? 0 : ''))
                        }
                    }

                    this.$store.dispatch('API', {
                        method: 'POST',
                        url: '/device/' + this.item.id + '/update',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        data: data
                    }).then(res => {
                        if(res.data) {
                            if(res.data.status === 'success') {
                                this.close(res.data.data)
                            }
                        } else if(res.response && res.response.status == 422) {
                            if(res.response.data.errors) {
                                this.$refs.createOrUpdate.setErrors(res.response.data.errors)
                            }
                        }

                        this.loading = false
                    })
                }
            })
        },
        generateValue(key, length = 16) {
            let string = '';

            const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                string += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            this.value[key] = string
        },
        jsonString(json=null) {
            if(json) {
                return JSON.stringify(json, null, 4)
            }

            return ''
        },
        setUserAnPasswordDefault() {
            this.value.device_user = this.data.default_device_user
            this.value.device_password = this.data.default_device_password
        },
        updateFirmware(id) {

            let data = new FormData()

            data.append('version_id', id)

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/device/' + this.item.id + '/update-firmware',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        this.updateSuccess = 'Комманда отправлена'
                    }
                }
            })
        }
    },
    computed: {
        schema() {

            let validate = {}

            validate.name = yup.string().min(2).max(255).required()
            validate.description = yup.string().nullable().min(2).max(1000)
            validate.device_user = yup.string().min(2).max(64).required()
            validate.device_password = yup.string().min(6).max(64).nullable()

            return yup.object(validate)
        },
        title() {
            if(this.item && this.item.name) {
                return this.item.name
            }

            return ''
        },
    }
}
</script>