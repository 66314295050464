const updateObj = (data, elements) => {
    for (const key in data) {
        if (typeof data[key] === 'object') {
            if (data[key] instanceof Array) {
                elements[key] = data[key]
            } else {
                updateObj(data[key], elements[key])
            }
        } else {
            elements[key] = data[key]
        }
    }
}

export default {
    Update (state, data) {
        state[data.key] = data.value
    },
    UpdateItems (state, data) {
        updateObj({
            [data.key]: data.value
        }, state)
    },
    Push (state, data) {
        state[data.key].push(data.value)
    },
    Unshift (state, data) {
        state[data.key].unshift(data.value)
    }
}