<template>
    <v-dialog
        v-model="openDialog"
        persistent
        width="600px"
    >
        <v-card>
            <v-card-title class="text-h5">
                {{ title }}
            </v-card-title>
            <v-card-text>
                <Form
                    @submit.prevent="submit"
                    ref="createOrUpdate"
                    :validation-schema="schema"
                >
                    <Field
                        v-model="name"
                        name="name"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Имя"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <Field
                        v-model="email"
                        name="email"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="E-mail"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <Field
                        v-model="password"
                        name="password"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Пароль"
                            required
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-text-field>
                    </Field>

                </Form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    variant="text"
                    @click="close()"
                >
                    Отмена
                </v-btn>
                <v-btn
                    color="green-darken-1"
                    variant="text"
                    :loading="loading"
                    @click="submit"
                >
                    {{ buttonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number,
            default: null
        },
        item: {
            type: Object,
            default: () => {
                return null
            }
        },
    },
    emits: ['close'],
    components: {
        Form,
        Field
    },
    name: 'AddOrEditAdmin',
    data: () => ({
        id: null,
        name: null,
        email: null,
        password: null,
        openDialog: false,
        loading: false
    }),
    watch: {
        open(value) {

            this.openDialog = value

            this.loading = false

            if(value) {

                this.id = null
                this.name = null
                this.email = null
                this.password = null

                if(this.item != null) {
                    this.id = this.item.id
                    this.name = this.item.name
                    this.email = this.item.email
                }
            }else{
                this.$refs.createOrUpdate.resetForm()
            }
        }
    },
    methods: {
        close(save = false) {
            this.$emit('close', {
                index: this.index,
                id: this.id,
                name: this.name,
                email: this.email,
                save: save
            })
        },
        submit() {

            this.$refs.createOrUpdate.validate().then(data => {
                if(data.valid) {
                    if(this.item != null && this.item.id) {
                        this.update()
                    }else{
                        this.create()
                    }
                }
            })

            
        },
        create() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/setting/user/create',
                data: {
                    name: this.name,
                    email: this.email,
                    password: this.password
                }
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        this.id = res.data.data.id
                        this.close(true)
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdate.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        update() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/setting/user/' + this.item.id + '/update',
                data: {
                    name: this.name,
                    email: this.email,
                    password: this.password
                }
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        this.close(true)
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdate.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
    },
    computed: {
        title() {
            return this.name ? 'Редактирование' : 'Добавить'
        },
        buttonText() {
            return this.name ? 'Сохранить' : 'Создать'
        },
        schema() {
            return yup.object({
                name: yup.string().required().min(2).max(255),
                email: yup.string().required(),
                password: yup.string().nullable().min(6),
            })
        }
    }
}
</script>