<template>
    <v-chart :option="option" />
</template>
<script>

import { use } from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"
import { GaugeChart } from "echarts/charts"
import {
    TooltipComponent,
    GridComponent,
    VisualMapComponent
} from "echarts/components"
import VChart, { THEME_KEY } from "vue-echarts"

use([
    CanvasRenderer,
    GaugeChart,
    TooltipComponent,
    GridComponent,
    VisualMapComponent
])

export default {
    name: 'SensorStatPressure',
    props: {
        value: {
            default: 0.0
        }
    },
    components: {
        VChart
    },
    provide: {
        [THEME_KEY]: "light"
    },
    data: () => ({
        option: null
    }),
    mounted () {
        this.setChart(this.value)
    },
    watch: {
        value(val) {
            this.setChart(val)
        }
    },
    methods: {
        setChart(val = 0.0) {
            this.option = {
                grid: {
                    top: '20%',
                    left: '0%',
                    right: '0%',
                    bottom: '20%',
                    containLabel: false
                },
                series: [
                    {
                        type: 'gauge',
                        min: 0,
                        max: 10,
                        splitNumber: 10,
                        radius: '65%',
                        axisLine: {
                            lineStyle: {
                                color: [[1, '#f00']],
                                width: 1
                            }
                        },
                        splitLine: {
                            distance: -8,
                            length: 8,
                            lineStyle: {
                                color: '#f00'
                            }
                        },
                        axisTick: {
                            distance: -8,
                            length: 6,
                            lineStyle: {
                                color: '#f00'
                            }
                        },
                        axisLabel: {
                            distance: -20,
                            color: '#f00',
                            fontSize: 12
                        },
                        anchor: {
                            show: true,
                            size: 5,
                            itemStyle: {
                                borderColor: '#000',
                                borderWidth: 0
                            }
                        },
                        pointer: {
                            offsetCenter: [0, '10%'],
                            icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                            length: '110%',
                            itemStyle: {
                                color: '#000'
                            }
                        },
                        detail: {
                            valueAnimation: true,
                            precision: 1
                        },
                        title: {
                            offsetCenter: [0, '-50%']
                        },
                        data: [
                            {
                                value: val,
                                detail: {
                                    show: false
                                }
                            }
                        ]
                    },
                    {
                        type: 'gauge',
                        min: 0,
                        max: 150,
                        splitNumber: 6,
                        radius: '60%',
                        axisLine: {
                            lineStyle: {
                                color: [[1, '#000']],
                                width: 1
                            }
                        },
                        splitLine: {
                            distance: -1,
                            length: 8,
                            lineStyle: {
                                color: '#000'
                            }
                        },
                        axisTick: {
                            distance: 0,
                            length: 5,
                            lineStyle: {
                                color: '#000'
                            }
                        },
                        axisLabel: {
                            distance: 5,
                            fontSize: 9,
                            color: '#000'
                        },
                        pointer: {
                            show: false
                        },
                        title: {
                            show: false
                        },
                        anchor: {
                            show: true,
                            size: 10,
                            itemStyle: {
                                color: '#000'
                            }
                        }
                    }
                ],
            }
        }
    },
    computed: {
        item() {
            return this.sensor
        },
    },
    created() {   
    },
    unmounted() {
    }
}
</script>