<template>
    <h1 class="text-h4 mb-3">{{ titleHeading }}</h1>

    <v-skeleton-loader
        :loading="preLoading"
        type="table-row@5"
        class="w-100"
    >
        <v-row>
            <v-col cols="12" lg="8">
                <v-row>
                    <v-col cols="12"><h2>Датчики</h2></v-col>
                    <v-col cols="12" md="6" v-for="(sensor, i) in sensors" :key="i">
                        <SensorStats :sensor="sensor" @editSensor="upSensor" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="4">
                <v-row>
                    <v-col cols="12"><h2>Управление</h2></v-col>
                    <v-col cols="12" v-for="(control, i) in controls" :key="i">
                        <ControlStats :control="control" @update="upControl" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-skeleton-loader>
</template>
<script>
import ControlStats from '@/components/device/stats/ControlStats'
import SensorStats from '@/components/device/stats/SensorStats'

export default {
    name: 'DeviceStatsPage',
    components: {
        ControlStats,
        SensorStats
    },
    data: () => ({
        title: '',
        loading: false,
        sensors: [],
        controls: [],
        subscriptionSensor: {
            topic: null,
            qos: 0
        },
        subscriptionControl: {
            topic: null,
            qos: 0
        }
    }),
    mounted () {
        this.init()
    },
    watch: {
    },
    methods: {
        init() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/device/' + this.$route.params.id + '/stats'
            }).then(res => {

                if(res.data){
                    if(res.data.status === 'success') {

                        this.sensors = res.data.data.sensors
                        this.controls = res.data.data.controls

                        this.subscriptionSensor = {
                            topic: 'sensor/' + res.data.data.device_id,
                            qos: 0
                        }
                        this.subscriptionControl = {
                            topic: 'control/' + res.data.data.device_id,
                            qos: 0
                        }

                        this.doSubscribeSensor(this.subscriptionSensor)
                        this.doSubscribeControl(this.subscriptionControl)
                        this.onMessage(this.subscriptionSensor, this.subscriptionControl)

                    }
                }

                this.loading = false
            })
        },
        doSubscribeSensor(subscriptionSensor) {
            
            const {topic, qos} = subscriptionSensor
            
            this.client.subscribe(topic, {qos}, (error) => {
                if (error) {
                    return
                }
            })
        },
        doSubscribeControl(subscriptionControl) {
            
            const {topic, qos} = subscriptionControl
            
            this.client.subscribe(topic, {qos}, (error) => {
                if (error) {
                    return
                }
            })
        },
        onMessage(topicSensor, topicControl) {
            this.client.on('message', (topic, message) => {
                if(topicSensor.topic && topic === topicSensor.topic) {
                    const data = message.toJSON()
                    const str = String.fromCharCode.apply(String, data.data)
                    const result = JSON.parse(str.replace(/'/g, '"'))

                    this.sensors.forEach(item => {
                        if(item.sensor_id === result.id) {
                            item.value = result.value
                        }
                    })
                }

                if(topicControl.topic && topic === topicControl.topic) {
                    const data = message.toJSON()
                    const str = String.fromCharCode.apply(String, data.data)
                    const result = JSON.parse(str.replace(/'/g, '"'))

                    this.controls.forEach(item => {
                        if(item.control_id === result.id) {
                            item.value = result.value
                        }
                    })
                }
                
            })
        },
        doUnSubscribeSensor() {
            const { topic } = this.subscriptionSensor

            this.client.unsubscribe(topic, error => {
                if (error) {
                    console.log('Unsubscribe error', error)
                }
            })
        },
        doUnSubscribeControl() {
            const { topic } = this.subscriptionControl

            this.client.unsubscribe(topic, error => {
                if (error) {
                    console.log('Unsubscribe error', error)
                }
            })
        },
        upControl(data) {

            let items = []

            this.controls.forEach(item => {
                if(item.id === data.id) {
                    item = data
                }

                items.push(item)
            })

            this.controls = items
        },
        upSensor(data) {
            let items = []

            this.sensors.forEach(item => {
                if(item.id === data.id) {
                    item = data
                }

                items.push(item)
            })

            this.sensors = items
        }
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
        preLoading() {
            return this.$store.state.preloader
        },
        client() {
            return this.$store.state.mqtt_client
        }
    },
    created() {   
    },
    unmounted() {
        this.doUnSubscribeSensor()
        this.doUnSubscribeControl()
    },
}
</script>