import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

// Moment-js
import moment from 'moment'
moment.locale('ru')

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

loadFonts()

const app = createApp(App)
    .use(router)
    .use(store)
    .use(PerfectScrollbar)
    .use(vuetify)
    .component('QuillEditor', QuillEditor)

app.config.globalProperties.$moment = moment

app.mount('#app')