<template>
    <div class="app-catalog">
        <router-view/>
    </div>
</template>
<script>
export default {
    name: 'CatalogPage',
    data: () => ({
    })
}
</script>