<template>
    <v-row
        no-gutters
    >
        <v-col
            md="6"
            sm="12"
        >

            <h1 class="text-h4 mb-3">Контакты поддержки</h1>

            <Form
                @submit.prevent="submit"
                ref="settingForm"
                :validation-schema="schema"
            >

                <Field
                    v-model="phone_name"
                    name="phone_name"
                    v-slot="{ field, errors }"  
                >
                    <v-text-field
                        v-bind="field"
                        :model-value="field.value"
                        label="Название кнопки телефона"
                        color="indigo-darken-3"
                        variant="underlined"
                        :error-messages="errors"
                        required
                        :disabled="loading"
                    >
                    </v-text-field>
                </Field>

                <Field
                    v-model="phone_value"
                    name="phone_value"
                    v-slot="{ field, errors }"  
                >
                    <v-text-field
                        v-bind="field"
                        :model-value="field.value"
                        label="Сылка на телефон"
                        color="indigo-darken-3"
                        variant="underlined"
                        :error-messages="errors"
                        required
                        :disabled="loading"
                    >
                    </v-text-field>
                </Field>

                <Field
                    v-model="email_name"
                    name="email_name"
                    v-slot="{ field, errors }"  
                >
                    <v-text-field
                        v-bind="field"
                        :model-value="field.value"
                        label="Название кнопки E-mail"
                        color="indigo-darken-3"
                        variant="underlined"
                        :error-messages="errors"
                        required
                        :disabled="loading"
                    >
                    </v-text-field>
                </Field>

                <Field
                    v-model="email_value"
                    name="email_value"
                    v-slot="{ field, errors }"  
                >
                    <v-text-field
                        v-bind="field"
                        :model-value="field.value"
                        label="Сылка на e-mail"
                        color="indigo-darken-3"
                        variant="underlined"
                        :error-messages="errors"
                        required
                        :disabled="loading"
                    >
                    </v-text-field>
                </Field>

                <div class="d-flex justify-space-between">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        :loading="loading"
                        @click="submit"
                    >
                        Сохранить
                    </v-btn>
                </div>
            </Form>
        </v-col>
    </v-row>
</template>
<script>
import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'SupportContactPage',
    components: {
        Form,
        Field
    },
    data: () => ({
        phone_name: null,
        phone_value: null,
        email_name: null,
        email_value: null,
        loading: false,
    }),
    mounted () {
        this.init()
    },
    methods: {
        init() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/setting/support-contact',
            }).then(res => {

                if(res.data.status === 'success') {
                    this.setField(res.data.data)
                }

                this.loading = false

            })
        },
        setField(fields) {
            for (let key in fields) {
                switch(key) {
                    case 'phone_name':
                        this.phone_name = fields[key]
                        break
                    case 'phone_value':
                        this.phone_value = fields[key]
                        break
                    case 'email_name':
                        this.email_name = fields[key]
                        break
                    case 'email_value':
                        this.email_value = fields[key]
                        break
                }
            }
        },
        submit() {

            this.$refs.settingForm.validate().then(data => {
                if(data.valid) {

                    this.save()
                }
            })
        },
        save() {

            this.loading = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/setting/support-contact/save',
                data: {
                    'phone_name': this.phone_name,
                    'phone_value': this.phone_value,
                    'email_name': this.email_name,
                    'email_value': this.email_value,
                }
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        this.setField(res.data.data)
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.settingForm.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        }
    },
    computed: {
        schema() {
            return yup.object({
                phone_name: yup.string().required().min(2).max(255),
                phone_value: yup.string().required().min(2).max(255),
                email_name: yup.string().required().min(2).max(255),
                email_value: yup.string().required().min(2).max(255),
            })
        }
    }
}
</script>