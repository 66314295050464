<template>
    <div class="app__breadcrumbs">
        <v-breadcrumbs :items="items">
            <template v-slot:prepend>
                <v-icon size="12" class="mr-1" icon="fa fa-house"></v-icon>
            </template>
            <template v-slot:title="{ item }">
                {{ item.text }}
            </template>
        </v-breadcrumbs>
    </div>
</template>
<script>
export default {
    name: 'BreadcrumbsList',
    computed: {
        items() {
            return this.$store.state.breadcrumbsItems
        }
    }
}
</script>