<template>
    <h1 class="text-h4 mb-1">{{ titleHeading }}</h1>

    <div class="app-catalog__category">
        <Form
            @submit.prevent="submit"
            ref="createOrUpdateCategory"
            :validation-schema="schema"
        >
            <v-row>
                <v-col sm="12" md="8">
                    <div v-if="fields.image" class="d-block mb-6">
                        <v-hover>
                            <template v-slot:default="{ isHovering, props }">
                                <v-img
                                    v-bind="props"
                                    :lazy-src="fields.image"
                                    max-height="200"
                                    :src="fields.image"
                                    class="elevation-4"
                                >
                                    <span
                                        v-show="isHovering"
                                        style="position: absolute; top: 0.8rem; right: 0.8rem;"
                                    >
                                        <v-btn
                                            v-if="!file"
                                            color="black"
                                            right
                                            size="small"
                                            icon
                                            variant="text"
                                            :href="fields.image"
                                            target="_blank"
                                        >
                                            <v-icon size="14">fa fa-arrow-up-right-from-square</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-show="isHovering"
                                            color="red"
                                            size="small"
                                            icon
                                            variant="text"
                                            @click="clearFile"
                                        >
                                            <v-icon size="14">fa fa-trash</v-icon>
                                        </v-btn>
                                    </span>
                                </v-img>
                            </template>
                        </v-hover>
                    </div>

                    <div v-else class="d-flex w-100 mb-4">
                        <input ref="image" type="file" value="" class="d-none" accept="image/*" @change="setFile" />
                        <v-btn
                            color="indigo-darken-3"
                            variant="outlined"
                            block
                            @click="addImage"
                        >
                            <template v-slot:prepend>
                                <v-icon size="14">fa fa-plus</v-icon>
                            </template>
                            Добавить изображение
                        </v-btn>
                    </div>

                    <Field
                        v-model="fields.title"
                        name="title"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Название"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <v-select
                        v-model="fields.type"
                        label="Тип слайда"
                        :items="typeItem"
                        item-title="name"
                        item-value="type"
                        variant="underlined"
                        color="indigo-darken-3"
                        menu-icon="fa fa-angle-down"
                        no-data-text="Ничего не найдено"
                        required
                        @update:modelValue="setType"
                    >
                    </v-select>

                    <div class="mt-3 mb-3" v-if="fields.type === 0">
                        <Field
                            v-model="fields.description"
                            name="description"
                            v-slot="{ field, errors, handleChange }"
                        >
                            <quill-editor
                                v-model:content="field.value"
                                placeholder="Описание" 
                                :enable="!loading"
                                contentType="html"
                                @update:content="handleChange"
                                toolbar="full"
                            >
                            </quill-editor>

                            <div v-if="errors.length" class="mt-2 v-messages text-error" style="opacity: 1;" role="alert">
                                <div class="v-messages__message">обязательно для заполнения</div>
                            </div>
                        </Field>
                    </div>

                    <Field
                        v-else-if="fields.type === 1 && fields.type_action.link != 'undefined'"
                        v-model="fields.type_action.link"
                        name="link"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Ссылка"
                            color="indigo-darken-3"
                            variant="underlined"
                            :disabled="loading"
                            :error-messages="errors"
                        />
                    </Field>

                    <Field
                        v-else-if="fields.type === 2 && fields.type_action.stock_id != 'undefined'"
                        v-model="fields.type_action.stock_id"
                        name="stock_id"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="ID акции"
                            color="indigo-darken-3"
                            variant="underlined"
                            :disabled="loading"
                            :error-messages="errors"
                        />
                    </Field>

                    <Field
                        v-else-if="fields.type === 3 && fields.type_action.product_id != 'undefined'"
                        v-model="fields.type_action.product_id"
                        name="product_id"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="ID товара"
                            color="indigo-darken-3"
                            variant="underlined"
                            :disabled="loading"
                            :error-messages="errors"
                        />
                    </Field>

                    <Field
                        v-else-if="fields.type === 4 && fields.type_action.category_id != 'undefined'"
                        v-model="fields.type_action.category_id"
                        name="category_id"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="ID раздела"
                            color="indigo-darken-3"
                            variant="underlined"
                            :disabled="loading"
                            :error-messages="errors"
                        />
                    </Field>

                    <Field
                        v-model="fields.sort"
                        name="sort"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Сортировка"
                            required
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-text-field>
                    </Field>

                    <v-switch
                      v-model="fields.status"
                      :label="fields.status ? 'Слайд включен' : 'Слайд выключен'"
                      color="indigo-darken-3"
                      hide-details
                      :disabled="loading"
                    ></v-switch>
                </v-col>            

                <v-col sm="12">
                    <div class="d-flex justify-space-between">
                        <v-btn
                            @click="$router.go(-1)"
                        >
                            Назад
                        </v-btn>
                        <v-btn
                            color="success"
                            :loading="loading"
                            @click="submit"
                        >
                            Сохранить
                        </v-btn>
                    </div>
                </v-col>

            </v-row>
        </Form>
    </div>
</template>
<script>

import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'SliderEditPage',
    components: {
        Form,
        Field
    },
    data: () => ({
        title: '',
        id: null,
        file: null,
        fields: {
            'title': null,
            'description': null,
            'image': null,
            'type': 0,
            'sort': 0,
            'status': true,
            'type_action': null
        },
        typeItem: [
            {
                type: 0,
                name: 'Страница'
            },
            {
                type: 1,
                name: 'Ссылка'
            },
            {
                type: 2,
                name: 'Акция'
            },
            {
                type: 3,
                name: 'Товар'
            },
            {
                type: 4,
                name: 'Раздел'
            },
            // {
            //     type: 5,
            //     name: 'Услуги'
            // },
        ],
        loading: false,
    }),
    mounted () {
        if(this.$route.params.id) this.id = this.$route.params.id
    },
    watch: {
        id() {
            this.init()
        },
    },
    methods: {
        init() {
            this.get()
        },
        get() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/slider/' + this.id + '/edit',
            }).then(res => {

                if(res.data.status === 'success') {

                    if(res.data.data.title) this.setTitle(res.data.data.title)

                    this.setField(res.data.data)

                }

                this.loading = false

            })
        },
        setTitle(title = '') {
            if(title) {
                this.$store.commit('Update', {
                    key: 'titlePage',
                    value: title
                })

                this.title = title
            }
        },
        setField(fields) {
            for (let key in fields) {
                if(this.fields[key] !== undefined) {
                    if (key == 'status')
                        this.fields[key] = fields[key] == 1 ? true : false
                    else if (key == 'type_action')
                        this.fields[key] = JSON.parse(fields[key])
                    else
                        this.fields[key] = fields[key]
                }
            }

            console.log(this.fields)
        },
        addImage() {
            this.$refs.image.click()
        },
        setFile(e) {
            if(e.target.files[0]) {
                this.file = e.target.files[0]
                
                let reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onload = e => {
                    this.fields.image = e.target.result
                }
            }
        },
        clearFile() {
            this.file = null
            this.fields.image = null
        },
        submit() {
            this.$refs.createOrUpdateCategory.validate().then(data => {
                if(data.valid) {
                    if(this.id) this.update()
                    else this.create()
                }
            })
            
        },
        create() {
            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key !== 'image' && key !== 'type_action') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : (value == 0 ? 0 : ''))
                    }
                }else if(key === 'type_action') {
                    if(value !== null)
                        data.append(key, JSON.stringify(value))
                }
            }

            if (this.file)
                data.append('image', this.file)

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/slider/create',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        if(res.data.data.title) this.setTitle(res.data.data.title)

                        this.setField(res.data.data)

                        if(res.data.data.id) this.$router.push({ name: 'slider_edit', params: { id: res.data.data.id }})
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateCategory.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        update() {

            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key !== 'image' && key !== 'type_action') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : (value == 0 ? 0 : ''))
                    }
                }else if(key === 'type_action') {
                    if(value !== null)
                        data.append(key, JSON.stringify(value))
                }
            }

            if (this.file)
                data.append('image', this.file)

            if(!this.file && !this.fields.image)
                data.append('remove_image', 1)


            this.$store.dispatch('API', {
                method: 'POST',
                url: '/slider/' + this.id + '/update',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {

                        if(res.data.data.title) this.setTitle(res.data.data.title)

                        this.setField(res.data.data)

                        this.file = null
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateCategory.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        setType() {
            if(this.fields.type === 0){
                this.fields.type_action = null
            }else if(this.fields.type === 1) {
                this.fields.type_action = {
                    link: null
                }
            }else if(this.fields.type === 2) {
                this.fields.type_action = {
                    stock_id: null
                }
            }else if(this.fields.type === 3) {
                this.fields.type_action = {
                    product_id: null
                }
            }else if(this.fields.type === 4) {
                this.fields.type_action = {
                    category_id: null
                }
            }
        }
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
        schema() {
            return yup.object({
                title: yup.string().required().min(2).max(255),
                description: this.fields.type === 0 ? yup.string().nullable().required() : yup.string().nullable(),
                sort: yup.number().nullable(),
            })
        }
    }
}
</script>