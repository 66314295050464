<template>
    <v-navigation-drawer
        absolute
        left
        elevation="6"
    >
        <v-list
            density="compact"
            nav
        >
            <v-list-item to="/dashboard">
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-house</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Главная</span>
                </template>
            </v-list-item>

            <v-list-item to="/device">
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-microchip</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">SmartBox</span>
                </template>
            </v-list-item>

            <v-divider class="mt-3 mb-3"></v-divider>

            <!-- <v-list-item>
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-cart-shopping</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Заказы</span>
                </template>
            </v-list-item> -->

            <!-- <v-list-item>
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-headset</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Сервис</span>
                </template>
            </v-list-item> -->

            <!-- <v-list-item>
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-envelope-open-text</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Связь с менеджером</span>
                </template>
            </v-list-item> -->

            <v-list-group
                collapse-icon="fa fa-angle-up"
                expand-icon="fa fa-angle-down"
            >

                <template v-slot:activator="{ props }">
                    <v-list-item
                        v-bind="props"
                    >
                        <template v-slot:prepend>
                            <v-icon size="20">fa fa-gears</v-icon>
                        </template>
                        <template v-slot:title>
                            <span class="font-weight-bold">Настройки заказов</span>
                        </template>
                    </v-list-item>
                </template>

                <v-list-item
                    to="/order-settings/delivery"
                    title="Доставка"
                ></v-list-item>
                <v-list-item
                    to="/order-settings/payment"
                    title="Платежные системы"
                ></v-list-item>
            </v-list-group>

            <v-divider class="mt-3 mb-3"></v-divider>

            <v-list-group
                collapse-icon="fa fa-angle-up"
                expand-icon="fa fa-angle-down"
            >

                <template v-slot:activator="{ props }">
                    <v-list-item
                        v-bind="props"
                    >
                        <template v-slot:prepend>
                            <v-icon size="20">fa fa-list</v-icon>
                        </template>
                        <template v-slot:title>
                            <span class="font-weight-bold">Каталог</span>
                        </template>
                    </v-list-item>
                </template>

                <v-list-item
                    to="/catalog/category"
                    title="Категории"
                ></v-list-item>
                <v-list-item
                    to="/catalog/product"
                    title="Товары"
                ></v-list-item>
            </v-list-group>

            <!-- <v-list-item>
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-hand-point-up</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Услуги</span>
                </template>
            </v-list-item> -->

            <v-list-item
                to="/stock"
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-percent</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Акции</span>
                </template>
            </v-list-item>

            <v-list-item
                to="/article"
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-newspaper</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Статьи</span>
                </template>
            </v-list-item>

            <v-list-item
                to="/faq"
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-question</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">FAQ</span>
                </template>
            </v-list-item>

            <v-list-item
                to="/slider"
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-sliders</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Слайды</span>
                </template>
            </v-list-item>

            <v-divider class="mt-3 mb-3"></v-divider>

            <v-list-item>
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-bell</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Уведомления</span>
                </template>
            </v-list-item>

            <v-list-item
                to='/user'
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-users</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Пользователи</span>
                </template>
            </v-list-item>

        </v-list>

        <template v-slot:append>
            <v-list
                density="compact"
                nav
            >
                <v-list-item
                    to="/setting"
                >
                    <template v-slot:prepend>
                        <v-icon size="20">fas fa-cog</v-icon>
                    </template>
                    <template v-slot:title>
                        <span class="font-weight-bold">Настройки</span>
                    </template>
                </v-list-item>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'NavigationPanel'
}
</script>