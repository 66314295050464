export default {
    isLoggedIn: state => !!state.token,

    subCategories: () => (categories) => {
        return getSubCategories(categories)
    },
    categories(state) {
        return categoryItems(state.categoryItems)
    }
}


function getSubCategories(categories) {
    let items = []
    categories.forEach(category => {
        items.push({
            'id': category.id,
            'category_id': category.category_id ? category.category_id : '',
            'title': category.title.trim(),
            'image': category.image ? category.image : '',
            'subCategories': getSubCategories(category.sub_categories.length > 0 ? category.sub_categories : [])
        })
    })

    return items
}

function categoryItems (categories) {
    let items = []

    categories.forEach(category => {
        items.push({
            'id': category.id,
            'category_id': category.category_id ? category.category_id : '',
            'title': category.title.trim(),
            'image': category.image ? category.image : '',
        })

        items.push.apply(items, categoryItems(category.subCategories))
    })

    return items
}