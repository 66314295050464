import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'

import MainLayout from '@/layouts/MainLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'

import SignIn from '@/pages/auth/SignIn.vue'
import ResetPassword from "@/pages/auth/ResetPassword.vue"
import NewPassword from "@/pages/auth/NewPassword.vue"

import Dashboard from '@/pages/Dashboard.vue'

import CatalogPage from '@/pages/CatalogPage.vue'
import CategoryPage from '@/pages/catalog/CategoryPage.vue'
import CategoryListPage from '@/pages/catalog/CategoryListPage.vue'
import CategoryEditPage from '@/pages/catalog/CategoryEditPage.vue'
import ProductPage from '@/pages/catalog/ProductPage.vue'
import ProductListPage from '@/pages/catalog/ProductListPage.vue'
import ProductEditPage from '@/pages/catalog/ProductEditPage.vue'

import OrderSettingsPage from '@/pages/OrderSettingsPage.vue'
import DeliveryPage from '@/pages/order_settings/DeliveryPage.vue'
import PaymentPage from '@/pages/order_settings/PaymentPage.vue'
import PaymentListPage from '@/pages/order_settings/PaymentListPage.vue'

import ArticlePage from '@/pages/article/ArticlePage.vue'
import ArticleListPage from '@/pages/article/ArticleListPage.vue'
import ArticleEditPage from '@/pages/article/ArticleEditPage.vue'

import StockPage from '@/pages/stock/StockPage.vue'
import StockListPage from '@/pages/stock/StockListPage.vue'
import StockEditPage from '@/pages/stock/StockEditPage.vue'

import FaqPage from '@/pages/FaqPage.vue'

import SliderPage from '@/pages/slider/SliderPage.vue'
import SliderListPage from '@/pages/slider/SliderListPage.vue'
import SliderEditPage from '@/pages/slider/SliderEditPage.vue'

import UserPage from '@/pages/user/UserPage.vue'
import UserListPage from '@/pages/user/UserListPage.vue'
import UserEditPage from '@/pages/user/UserEditPage.vue'

import SettingPage from '@/pages/setting/SettingPage.vue'
import DefaultSettingPage from '@/pages/setting/DefaultSettingPage.vue'
import SupportContactPage from '@/pages/setting/SupportContactPage.vue'
import AboutLinkPage from '@/pages/setting/AboutLinkPage.vue'
import AdminListPage from '@/pages/setting/AdminListPage.vue'

import DevicePage from '@/pages/device/DevicePage.vue'
import DeviceListPage from '@/pages/device/DeviceListPage.vue'
// import DeviceViewPage from '@/pages/device/DeviceViewPage.vue'
import DeviceStatsPage from '@/pages/device/DeviceStatsPage.vue'

const routes = [
    {
        path: '/',
        name: 'main',
        component: MainLayout,
        meta: {
            requiresAuth: true,
            title: 'Главная',
        },
        children: [
            {
                path: '',
                name: 'home',
                component: Dashboard,
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                redirect: '/',
            },
            {
                path: 'device',
                name: 'device',
                component: DevicePage,
                meta: {
                    title: 'SmartBox',
                },
                children: [
                    {
                        path: '',
                        name: 'device_page',
                        component: DeviceListPage,
                    },
                    {
                        path: ':id/stats',
                        name: 'device_stats',
                        component: DeviceStatsPage,
                        meta: {
                            title: 'Статистика',
                        }
                    }
                ]
            },
            {
                path: 'catalog',
                name: 'catalog',
                component: CatalogPage,
                meta: {
                    title: 'Каталог',
                },
                redirect: '/catalog/category',
                children: [
                    {
                        path: 'category',
                        name: 'category_page',
                        component: CategoryPage,
                        meta: {
                            title: 'Категории',
                        },
                        children: [
                            {
                                path: '',
                                name: 'category',
                                component: CategoryListPage
                            },
                            {
                                path: ':id',
                                name: 'category_list',
                                component: CategoryListPage,
                                meta: {
                                    title: 'Категория',
                                }
                            },
                            {
                                path: ':id/edit',
                                name: 'category_edit',
                                component: CategoryEditPage,
                                meta: {
                                    title: 'Редактирование категории',
                                }
                            },
                            {
                                path: 'add',
                                name: 'category_add',
                                component: CategoryEditPage,
                                meta: {
                                    title: 'Добавить категорию',
                                }
                            },
                        ]
                    },
                    
                    {
                        path: 'product',
                        name: 'product_page',
                        component: ProductPage,
                        meta: {
                            title: 'Товары',
                        },
                        children: [
                            {
                                path: '',
                                name: 'product',
                                component: ProductListPage
                            },
                            {
                                path: ':id/edit',
                                name: 'product_edit',
                                component: ProductEditPage,
                                meta: {
                                    title: 'Редактирование товара',
                                }
                            },
                            {
                                path: 'add',
                                name: 'product_add',
                                component: ProductEditPage,
                                meta: {
                                    title: 'Добавить товар',
                                }
                            },
                        ]
                    },
                ]
            },
            {
                path: 'order-settings',
                name: 'order_settings_page',
                component: OrderSettingsPage,
                meta: {
                    title: 'Настройки заказов',
                },
                redirect: '/order-settings/payment',
                children: [
                    {
                        path: 'delivery',
                        name: 'order_delivery_setting_page',
                        component: DeliveryPage,
                        meta: {
                            title: 'Доставка',
                        },
                        children: [
                            // {
                            //     path: '',
                            //     name: 'order_payment_list',
                            //     component: PaymentListPage
                            // }
                        ]
                    },
                    {
                        path: 'payment',
                        name: 'order_payment_setting_page',
                        component: PaymentPage,
                        meta: {
                            title: 'Платежные системы',
                        },
                        children: [
                            {
                                path: '',
                                name: 'order_payment_list',
                                component: PaymentListPage
                            }
                        ]
                    },
                ]
            },
            {
                path: 'article',
                name: 'article',
                component: ArticlePage,
                meta: {
                    title: 'Статьи',
                },
                children: [
                    {
                        path: '',
                        name: 'article_list',
                        component: ArticleListPage
                    },
                    {
                        path: ':id/edit',
                        name: 'article_edit',
                        component: ArticleEditPage,
                        meta: {
                            title: 'Редактирование статьи',
                        }
                    },
                    {
                        path: 'add',
                        name: 'article_add',
                        component: ArticleEditPage,
                        meta: {
                            title: 'Добавить статью',
                        }
                    },
                ]
            },
            {
                path: 'stock',
                name: 'stock',
                component: StockPage,
                meta: {
                    title: 'Акции',
                },
                children: [
                    {
                        path: '',
                        name: 'stock_list',
                        component: StockListPage
                    },
                    {
                        path: ':id/edit',
                        name: 'stock_edit',
                        component: StockEditPage,
                        meta: {
                            title: 'Редактирование акцию',
                        }
                    },
                    {
                        path: 'add',
                        name: 'stock_add',
                        component: StockEditPage,
                        meta: {
                            title: 'Добавить акцию',
                        }
                    },
                ]
            },
            {
                path: 'faq',
                name: 'faq',
                component: FaqPage,
                meta: {
                    title: 'FAQ',
                },
            },
            {
                path: 'slider',
                name: 'slider',
                component: SliderPage,
                meta: {
                    title: 'Слайды',
                },
                children: [
                    {
                        path: '',
                        name: 'slider_list',
                        component: SliderListPage
                    },
                    {
                        path: ':id/edit',
                        name: 'slider_edit',
                        component: SliderEditPage,
                        meta: {
                            title: 'Редактирование слайда',
                        }
                    },
                    {
                        path: 'add',
                        name: 'slider_add',
                        component: SliderEditPage,
                        meta: {
                            title: 'Добавить слайд',
                        }
                    },
                ]
            },
            {
                path: 'user',
                name: 'user',
                component: UserPage,
                meta: {
                    title: 'Пользователи',
                },
                children: [
                    {
                        path: '',
                        name: 'user_list',
                        component: UserListPage
                    },
                    {
                        path: ':id/edit',
                        name: 'user_edit',
                        component: UserEditPage,
                        meta: {
                            title: 'Редактирование пользователя',
                        }
                    },
                ]
            },
            {
                path: 'setting',
                name: 'setting',
                component: SettingPage,
                meta: {
                    title: 'Настройки',
                },
                redirect: '/setting/default',
                children: [
                    {
                        path: 'default',
                        name: 'default_setting',
                        component: DefaultSettingPage
                    },
                    {
                        path: 'support-contact',
                        name: 'support_contact',
                        component: SupportContactPage
                    },
                    {
                        path: 'about-link',
                        name: 'about_link',
                        component: AboutLinkPage
                    },
                    {
                        path: 'user',
                        name: 'admin_list',
                        component: AdminListPage,
                        meta: {
                            title: 'Список пользователей',
                        }
                    }
                ]
            },
        ]
    },
    {
        path: '/auth',
        name: 'auth',
        component: AuthLayout,
        redirect: '/auth/sign-in',
        meta: {
            requiresAuth: false,
            title: 'Авторизация',
        },
        children: [
            {
                path: 'sign-in',
                name: 'sign_in',
                component: SignIn,
                meta: {
                    title: 'Вход в аккаунт',
                }
            },
            {
                path: 'reset',
                name: 'reset_password',
                component: ResetPassword,
                meta: {
                    title: 'Сбросить пароль',
                }
            },
            {
                path: 'new-password/:id',
                name: 'new_password',
                component: NewPassword,
                meta: {
                    title: 'Создание пароля',
                }
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    // strict: true
})

router.beforeEach((to, from, next) => {

    const currentUser = store.getters.isLoggedIn
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    if (requiresAuth && !currentUser) {
        const loginpath = window.location.pathname
        next({ name: 'auth', query: { from: loginpath } })
    } else if (!requiresAuth && currentUser) {
        // if(to.name !== 'permission')
        //     next({ name: 'permission' })
        // else
        //     next()
        next({ name: 'main' })
    } else {
        next()
    }
})

export default router
