<template>
    <v-dialog
        v-model="open"
        persistent
        max-width="600"
    >
        <v-card
        >
            <v-skeleton-loader
                :loading="getLoading"
                type="subtitle, list-item@5"
                class="w-100 d-block"
            >
                <v-card-title>
                    {{ item.name }}
                </v-card-title>

                <v-card-subtitle v-if="typeName">
                    {{ typeName }}
                </v-card-subtitle>

                <v-card-item>
                    <Form
                        @submit.prevent="submit"
                        ref="updateForm"
                        :validation-schema="schema"
                    >

                        <v-text-field
                            v-model="item.sensor_id"
                            label="ID датчика"
                            color="indigo-darken-3"
                            variant="underlined"
                            disabled
                        />

                        <Field
                            v-model="item.name"
                            name="name"
                            v-slot="{ field, errors }"  
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                label="Название"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                :disabled="loading"
                            />
                        </Field>

                        <Field
                            v-model="item.description"
                            name="description"
                            v-slot="{ field, errors }"  
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                label="Описание"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                :disabled="loading"
                            />
                        </Field>

                        <Field
                            v-if="item.type === 0 && this.item.options && this.item.options.correction !== 'undefined'"
                            v-model="item.options.correction"
                            name="options.correction"
                            v-slot="{ field, errors }"
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                type="number"
                                label="Скорректировать датчик температуры"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                :disabled="loading"
                                min="-10"
                                max="10"
                                step="0.5"
                            />
                        </Field>

                        <Field
                            v-else-if="item.type === 2 && this.item.options && this.item.options.correction !== 'undefined'"
                            v-model="item.options.correction"
                            name="correction"
                            v-slot="{ field, errors }"
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                type="number"
                                label="Скорректировать датчик давления"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                :disabled="loading"
                                :min="minPressure"
                                :max="maxPressure"
                                :step="stepPressure"
                            >
                                <template v-slot:append>
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn
                                                variant="plain"
                                                v-bind="props"
                                                density="compact"
                                            >
                                                {{ pressureLabel }}
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-list-item :active="pressureValue === 0" @click="setPressure(0)">
                                                <v-list-item-title>МПа</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item :active="pressureValue === 1" @click="setPressure(1)">
                                                <v-list-item-title>бар</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item :active="pressureValue === 2" @click="setPressure(2)">
                                                <v-list-item-title>psi</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </Field>

                        <Field
                            v-else-if="item.type === 4 && this.item.options && this.item.options.correction !== 'undefined'"
                            v-model="item.options.correction"
                            name="correction"
                            v-slot="{ field, errors }"
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                type="number"
                                label="Скорректировать датчик веса"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                :disabled="loading"
                                min="-10"
                                max="10"
                                step="0.5"
                            />
                        </Field>

                        <v-switch
                            v-else-if="item.type === 3"
                            v-model="item.set_value"
                            name="set_value"
                            label="Сбросить показания"
                            color="primary"
                        ></v-switch>
                    </Form>
                </v-card-item>
            </v-skeleton-loader>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-3"
                    text="Закрыть"
                    @click="open = false"
                ></v-btn>
                <v-btn
                    color="success"
                    text="Сохранить"
                    @click="submit"
                    :disabled="getLoading"
                    :loading="loading"
                ></v-btn>
            </template>
        </v-card>
    </v-dialog>
</template>
<script>
import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'SettingSensorPopup',
    emits: ['close', 'update'],
    props: {
        sensor_id: {
            type: Number,
            default: null
        }
    },
    components: {
        Form,
        Field
    },
    data: () => ({
        open: false,
        item: {},
        getLoading: false,
        loading: false,
    }),
    mounted () {
    },
    watch: {
        sensor_id(value) {
            if(value) {
                this.open = true
            }else{
                this.open = false
            }
        },
        open(status) {
            if(status) {
                this.init()
            }else{
                this.$emit('close')
                this.item = {}
                this.$refs.updateForm.resetForm()
            }
        }
    },
    methods: {
        init() {
            this.getLoading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/device/' + this.$route.params.id + '/stats/sensor/' + this.sensor_id + '/setting'
            }).then(res => {

                if(res.data){
                    if(res.data.status === 'success') {
                        this.item = res.data.data

                        if(this.item.type === 2 && this.item.options && this.item.options.correction !== 'undefined'){
                            if(this.pressureValue == 1) {
                                this.item.options.correction = (this.item.options.correction * 10).toFixed(1)
                            }else if(this.pressureValue == 2){
                                this.item.options.correction = Math.round(this.item.options.correction * 145)
                            }
                        }
                    }
                }

                this.getLoading = false
            })
        },
        submit() {
            this.$refs.updateForm.validate().then(data => {
                if(data.valid) {

                    let item = JSON.parse(JSON.stringify(this.item))

                    if(item.type === 2 && item.options && item.options.correction !== 'undefined'){
                        if(this.pressureValue == 1) {
                            item.options.correction = (item.options.correction/10).toFixed(2)
                        }else if(this.pressureValue == 2){
                            item.options.correction = (item.options.correction/145).toFixed(5)
                        }
                    }

                    this.loading = true

                    this.$store.dispatch('API', {
                        method: 'POST',
                        url: '/device/' + this.$route.params.id + '/stats/sensor/' + this.sensor_id + '/setting',
                        data: item
                    }).then(res => {
                        if(res.data) {
                            if(res.data.status === 'success') {
                                this.item = res.data.data
                                this.$emit('update', this.item)
                            }
                        } else if(res.response && res.response.status == 422) {
                            if(res.response.data.errors) {

                                this.$refs.updateForm.setErrors(res.response.data.errors)
                            }
                        }

                        this.loading = false
                    })
                }
            })
        },
        setPressure(val = 0) {
            this.$store.commit('Update', {
                key: 'pressureValue',
                value: val
            })
        },
    },
    computed: {
        schema() {

            // let correction = yup.number().required()

            // if(this.item && this.item.options) {
            //     if(this.item.options.correction !== 'undefined') {
            //         if(this.item.type === 0 || this.item.type === 4) {
            //             correction = correction.min(-10).max(10)
            //         }
            //     }
            // }


            return yup.object({
                name: yup.string().required().min(2).max(255),
                description: yup.string().nullable().max(1000),
                // 'options.correction': correction
            })
        },
        typeName() {

            let name = ''

            switch(this.item.type) {
                case 0:
                    name = 'Детчик температуры'
                    break
                case 1:
                    name = 'Датчик протечки'
                    break
                case 2:
                    name = 'Датчик давления'
                    break
                case 3:
                    name = 'Датчик потока'
                    break
                case 4:
                    name = 'Датчик веса'
                    break
            }

            return name
        },
        pressureValue() {
            return this.$store.state.pressureValue
        },
        pressureLabel() {
            if (this.pressureValue === 1) return 'бар'
            else if(this.pressureValue === 2) return 'psi'

            return 'МПа'
        },
        minPressure()
        {

            if (this.pressureValue == 0) {
                return -1
            }else if(this.pressureValue == 2) {
                return -100
            }

            return -10
        },
        maxPressure() {

            if (this.pressureValue == 0) {
                return 1
            }else if(this.pressureValue == 2) {
                return 100
            }

            return 10
        },
        stepPressure() {

            if (this.pressureValue == 0) {
                return 0.05
            }else if(this.pressureValue == 2) {
                return 1
            }

            return 0.5
        }
    },
    created() {   
    },
    unmounted() {
        this.open = false
    }
}
</script>