<template>
    <p align="center" class="text-h5 mb-2">Создайте новый пароль:</p>

    <Form
        class="mt-4"
        @submit.prevent="onSubmit"
        ref="signIn"
    >

        <v-alert
            v-if="errorMessage"
            type="error"
            variant="outlined"
            icon="fas fa-exclamation"
            class="mb-4"
        >
            {{ errorMessage }}
        </v-alert>

        <Field
            v-model="password"
            name="password"
            :rules="validatePassword"
            v-slot="{ field, errors }"
        >
            <v-text-field
                v-bind="field"
                label="Пароль"
                :type="hiddenPassword ? 'password' : 'text'"
                color="indigo-darken-3"
                variant="underlined"
                :error-messages="errors"
                required
                class="mt-2"
                :disabled="loading"
            >
                <template v-slot:append>
                    <v-btn @click="hiddenPassword = !hiddenPassword" variant="text" icon density="compact">
                        <v-icon size="16" v-if="hiddenPassword">
                            fa fa-eye-slash
                        </v-icon>
                        <v-icon size="16" v-else>
                            fa fa-eye
                        </v-icon>
                    </v-btn>
                </template>
            </v-text-field>
        </Field>

        <Field
            v-model="passwordConfirmation"
            name="passwordConfirmation"
            :rules="validatePasswordConfirmation"
            v-slot="{ field, errors }"
        >
            <v-text-field
                v-bind="field"
                label="Повторите пароль"
                :type="hiddenPassword ? 'password' : 'text'"
                color="indigo-darken-3"
                variant="underlined"
                :error-messages="errors"
                required
                class="mt-2"
                :disabled="loading"
            />
        </Field>

        <v-btn
            block
            class="mt-2"
            color="indigo-darken-3"
            type="submit"
            @click.prevent="onSubmit"
            :loading="loading"
        >
            Сменить пароль
        </v-btn>

    </Form>

    <v-btn :to="{ name: 'sign_in' }" variant="text" class="mt-2" block>
        Войти
    </v-btn>

</template>

<script>
import { Form, Field } from 'vee-validate';

export default {
    name: 'SignIn',
    components: {
        Form,
        Field
    },
    data: () => ({
        password: '',
        passwordConfirmation: '',
        errorMessage: '',
        loading: false,
        hiddenPassword: true,
        status: false,
    }),
    mounted () {
        this.init()
    },
    methods: {
        init() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/oauth/new-password/' + this.$route.params.id,
            }).then(res => {

                this.errorMessage = ''

                if(res.data) {
                    if(res.data.status === 'success') {
                        this.loading = false
                        this.status = true
                    }else{
                        if(res.data.message)
                            this.errorMessage = res.data.message
                    }
                } else if(res.response && res.response.status == 404) {
                    this.errorMessage = 'Ошибка'
                }

                this.loading = false
            })
        },
        onSubmit() {
            this.$refs.signIn.validate().then(data => {
                if(data.valid)
                    this.submit()
            })
        },
        submit() {
            if(this.status) {
                this.loading = true

                this.$store.dispatch('API', {
                    method: 'POST',
                    url: '/oauth/set-password',
                    data: {
                        token: this.$route.params.id,
                        password: this.password,
                        password_confirmation: this.passwordConfirmation,
                    }
                }).then(res => {

                    this.errorMessage = ''

                    if(res.data) {
                        if(res.data.status === 'success') {
                            this.$router.push({ name: 'sign_in' })
                        }else{
                            if(res.data.message)
                                this.errorMessage = res.data.message
                        }
                    }

                    this.loading = false
                })
            }
        },
        validatePassword(value) {
            if(!value)
                return 'Введите пароль'

            if(value.length < 6)
                return 'Пароль не должен быть меньше 6 символов'

            return true
        },
        validatePasswordConfirmation(value) {
            if((this.password && !value) || this.password !== value)
                return 'Повторите пароль'

            return true
        }
    },
}
</script>
