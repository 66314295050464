<template>
    <h1 class="text-h4 mb-1">{{ titleHeading }}</h1>

    <div class="app-catalog__category">
        <Form
            @submit.prevent="submit"
            ref="createOrUpdateProduct"
            :validation-schema="schema"
        >
            <v-row>
                <v-col sm="12" md="8">
                    <div v-if="form.images.length" class="d-block mb-6">
                        <v-row>
                            <v-col md="3" v-for="(image, key) in form.images" :key="key">
                                <v-hover>
                                    <template v-slot:default="{ isHovering, props }">
                                        <v-img
                                            v-bind="props"
                                            :lazy-src="image.url"
                                            :src="image.url"
                                            class="elevation-4"
                                        >
                                            <span
                                                v-show="isHovering"
                                                style="position: absolute; top: 0.8rem; right: 0.8rem;"
                                            >
                                                <v-btn
                                                    v-if="image.data_id"
                                                    color="black"
                                                    right
                                                    size="small"
                                                    icon
                                                    variant="text"
                                                    :href="image.url"
                                                    target="_blank"
                                                >
                                                    <v-icon size="14">fa fa-arrow-up-right-from-square</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    v-show="isHovering"
                                                    color="red"
                                                    size="small"
                                                    icon
                                                    variant="text"
                                                    @click="clearFile(image.id)"
                                                >
                                                    <v-icon size="14">fa fa-trash</v-icon>
                                                </v-btn>
                                            </span>
                                        </v-img>
                                    </template>
                                </v-hover>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex w-100 mb-4">
                        <input ref="image" type="file" value="" class="d-none" accept="image/*" @change="setFileImages" multiple="multiple"/>
                        <v-btn
                            color="indigo-darken-3"
                            variant="outlined"
                            block
                            @click="addImage"
                            :disabled="loading"
                        >
                            <template v-slot:prepend>
                                <v-icon size="14">fa fa-plus</v-icon>
                            </template>
                            Добавить изображение
                        </v-btn>
                    </div>

                    <Field
                        v-model="form.title"
                        name="title"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Название"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <v-autocomplete
                        v-model="form.category"
                        label="Категория"
                        :items="notSubCategories"
                        item-title="title"
                        item-value="id"
                        variant="underlined"
                        color="indigo-darken-3"
                        menu-icon="fa fa-angle-down"
                        clearable
                        clear-icon="fa fa-close"
                        no-data-text="Ничего не найдено"
                        @update:modelValue="clearCategories"
                    >
                    </v-autocomplete>

                    <v-autocomplete
                        v-model="form.categories"
                        label="Дополнительные категории"
                        :items="notSubCategories"
                        item-title="title"
                        item-value="id"
                        variant="underlined"
                        color="indigo-darken-3"
                        menu-icon="fa fa-angle-down"
                        :item-props="selectCategories"
                        clearable
                        clear-icon="fa fa-close"
                        no-data-text="Ничего не найдено"
                        multiple
                        @click:clear="clearCategories"
                        chips
                    >
                    </v-autocomplete>

                    <Field
                        v-model="form.description"
                        name="description"
                        v-slot="{ field, errors }"  
                    >
                        <v-textarea
                            v-bind="field"
                            :model-value="field.value"
                            label="Описание"
                            auto-grow
                            color="indigo-darken-3"
                            variant="underlined"
                            rows="1"
                            row-height="15"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-textarea>
                    </Field>

                    <Field
                        v-model="form.data_id"
                        name="data_id"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="ID на сайте Экволс"
                            color="indigo-darken-3"
                            variant="underlined"
                            :disabled="loading"
                            :error-messages="errors"
                        />
                    </Field>

                    <Field
                        v-model="form.data_xml_id"
                        name="data_xml_id"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="XML code"
                            color="indigo-darken-3"
                            variant="underlined"
                            :disabled="loading"
                            :error-messages="errors"
                        />
                    </Field>

                    <Field
                        v-model="form.article"
                        name="article"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Артикул"
                            required
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-text-field>
                    </Field>

                    <Field
                        v-model="form.d_model"
                        name="d_model"
                        v-slot="{ field, errors }"  
                    >
                        <v-textarea
                            v-bind="field"
                            :model-value="field.value"
                            label="3D модель"
                            auto-grow
                            color="indigo-darken-3"
                            variant="underlined"
                            rows="1"
                            row-height="15"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-textarea>
                    </Field>

                    <Field
                        v-model="form.video"
                        name="video"
                        v-slot="{ field, errors }"  
                    >
                        <v-textarea
                            v-bind="field"
                            :model-value="field.value"
                            label="Видео"
                            auto-grow
                            color="indigo-darken-3"
                            variant="underlined"
                            rows="1"
                            row-height="15"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-textarea>
                    </Field>

                    <Field
                        v-model="form.price"
                        name="price"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Стоимость"
                            required
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-text-field>
                    </Field>

                    <Field
                        v-model="form.stock_price"
                        name="stock_price"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Стоимость по скидке"
                            required
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-text-field>
                    </Field>

                    <Field
                        v-model="form.quantity"
                        name="quantity"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Количество"
                            required
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-text-field>
                    </Field>

                    <Field
                        v-model="form.rating"
                        name="rating"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Рейтинг"
                            required
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-text-field>
                    </Field>

                    <Field
                        v-model="form.url"
                        name="url"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Ссылка на товар (www.ecvols.ru)"
                            color="indigo-darken-3"
                            variant="underlined"
                            :disabled="loading"
                            :error-messages="errors"
                        />
                    </Field>

                    <div v-if="form.drawings.length" class="d-block mb-6">
                        <v-row>
                            <v-col md="3" v-for="(image, key) in form.drawings" :key="key">
                                <v-hover>
                                    <template v-slot:default="{ isHovering, props }">
                                        <v-img
                                            v-bind="props"
                                            :lazy-src="image.url"
                                            :src="image.url"
                                            class="elevation-4"
                                        >
                                            <span
                                                v-show="isHovering"
                                                style="position: absolute; top: 0.8rem; right: 0.8rem;"
                                            >
                                                <v-btn
                                                    v-if="image.data_id"
                                                    color="black"
                                                    right
                                                    size="small"
                                                    icon
                                                    variant="text"
                                                    :href="image.url"
                                                    target="_blank"
                                                >
                                                    <v-icon size="14">fa fa-arrow-up-right-from-square</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    v-show="isHovering"
                                                    color="red"
                                                    size="small"
                                                    icon
                                                    variant="text"
                                                    @click="clearFile(image.id)"
                                                >
                                                    <v-icon size="14">fa fa-trash</v-icon>
                                                </v-btn>
                                            </span>
                                        </v-img>
                                    </template>
                                </v-hover>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-else class="d-flex w-100 mb-4">
                        <input ref="imageDrawing" type="file" value="" class="d-none" accept="image/*" @change="setImageDrawings" />
                        <v-btn
                            color="indigo-darken-3"
                            variant="outlined"
                            block
                            @click="addImageDrawing"
                            :disabled="loading"
                        >
                            <template v-slot:prepend>
                                <v-icon size="14">fa fa-plus</v-icon>
                            </template>
                            Добавить чертеж
                        </v-btn>
                    </div>

                    <div v-if="form.works.length" class="d-block mb-6">
                        <v-row>
                            <v-col md="3" v-for="(image, key) in form.works" :key="key">
                                <v-hover>
                                    <template v-slot:default="{ isHovering, props }">
                                        <v-img
                                            v-bind="props"
                                            :lazy-src="image.url"
                                            :src="image.url"
                                            class="elevation-4"
                                        >
                                            <span
                                                v-show="isHovering"
                                                style="position: absolute; top: 0.8rem; right: 0.8rem;"
                                            >
                                                <v-btn
                                                    v-if="image.data_id"
                                                    color="black"
                                                    right
                                                    size="small"
                                                    icon
                                                    variant="text"
                                                    :href="image.url"
                                                    target="_blank"
                                                >
                                                    <v-icon size="14">fa fa-arrow-up-right-from-square</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    v-show="isHovering"
                                                    color="red"
                                                    size="small"
                                                    icon
                                                    variant="text"
                                                    @click="clearFile(image.id)"
                                                >
                                                    <v-icon size="14">fa fa-trash</v-icon>
                                                </v-btn>
                                            </span>
                                        </v-img>
                                    </template>
                                </v-hover>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex w-100 mb-4">
                        <input ref="imageWork" type="file" value="" class="d-none" accept="image/*" @change="setImageWorks" multiple="multiple" />
                        <v-btn
                            color="indigo-darken-3"
                            variant="outlined"
                            block
                            @click="addImageWork"
                            :disabled="loading"
                        >
                            <template v-slot:prepend>
                                <v-icon size="14">fa fa-plus</v-icon>
                            </template>
                            Добавить работы
                        </v-btn>
                    </div>

                    <v-switch
                      v-model="form.status"
                      :label="form.status ? 'Товар включена' : 'Товар выключена'"
                      color="indigo-darken-3"
                      hide-details
                      :disabled="loading"
                    ></v-switch>

                </v-col>

                <v-col sm="12" md="4">
                    <div class="text-h5">Настройки импорта товара</div>

                    <v-switch
                        v-model="form.import_image"
                        label="Не обновлять изображения"
                        color="indigo-darken-3"
                        hide-details
                        :disabled="loading || (!loading && !form.data_id)"
                    ></v-switch>

                    <v-switch
                        v-model="form.import_title"
                        label="Не обновлять название"
                        color="indigo-darken-3"
                        hide-details
                        :disabled="loading || (!loading && !form.data_id)"
                    ></v-switch>

                    <v-switch
                        v-model="form.import_category"
                        label="Не обновлять категорию(и)"
                        color="indigo-darken-3"
                        hide-details
                        :disabled="loading || (!loading && !form.data_id)"
                    ></v-switch>

                    <v-switch
                        v-model="form.import_description"
                        label="Не обновлять описание"
                        color="indigo-darken-3"
                        hide-details
                        :disabled="loading || (!loading && !form.data_id)"
                    ></v-switch>
                </v-col>
            

                <v-col sm="12">
                    <div class="d-flex justify-space-between">
                        <v-btn
                            @click="$router.go(-1)"
                        >
                            Назад
                        </v-btn>
                        <v-btn
                            color="success"
                            :loading="loading"
                            @click="submit"
                        >
                            Сохранить
                        </v-btn>
                    </div>
                </v-col>

            </v-row>
        </Form>
    </div>
</template>
<script>

import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'ProductEditPage',
    components: {
        Form,
        Field
    },
    data: () => ({
        title: '',
        id: null,
        files: [],
        imageDrawings: [],
        imageWorks: [],
        removeImages: [],
        form: {
            'title': null,
            'import_title': false,
            'description': null,
            'import_description': false,
            'category': null,
            'categories': [],
            'import_category': false,
            'data_id': null,
            'data_xml_id': null,
            'drawings': [],
            'works': [],
            'images': [],
            'import_image': false,
            'price': 0.00,
            'stock_price': 0.00,
            'article': null,
            'd_model': null,
            'video': null,
            'quantity': 0,
            'rating': 0.00,
            'url': null,
            'status': true,
        },
        loading: false,
    }),
    mounted () {
        if(this.$route.params.id) this.id = this.$route.params.id

        this.getCategories()
    },
    watch: {
        id() {
            this.init()
        }
    },
    methods: {
        init() {
            this.get()
        },
        get() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/catalog/product/' + this.id + '/edit',
            }).then(res => {

                if(res.data.status === 'success') {

                    if(res.data.data.title) this.setTitle(res.data.data.title)

                    this.setField(res.data.data)

                }

                this.loading = false

            })
        },
        getCategories() {
            this.$store.dispatch('API', {
                method: 'GET',
                url: '/catalog/category/list',
            }).then(res => {

                if(res.data.status === 'success') {
                    this.$store.commit('Update', {
                        key: 'categoryItems',
                        value: this.$store.getters.subCategories(res.data.data)
                    })
                }
            })
        },
        setTitle(title = '') {
            if(title) {
                this.$store.commit('Update', {
                    key: 'titlePage',
                    value: title
                })

                this.title = title
            }
        },
        setField(form) {

            this.files = []
            this.imageDrawings = []
            this.imageWorks = []
            this.removeImages = []

            for (let key in form) {
                if(this.form[key] !== undefined) {
                    if (key == 'status' || key == 'import_image' || key == 'import_title' || key == 'import_description' || key == 'import_category_id'){
                        this.form[key] = form[key] == 1 ? true : false
                    }else if (key == 'category') {
                        this.form[key] = form[key].id
                    }else if(key == 'categories'){
                        let categories = []

                        form[key].forEach(category => {
                            categories.push(category.id)
                        })

                        this.form[key] = categories
                    }else{
                        this.form[key] = form[key]
                    }
                }
            }
        },
        selectCategories(item) {

            let disabled = false

            if (this.form.category == item.id) disabled = true

            return {
                id: item.id,
                title: item.title,
                disabled: disabled
            }
        },
        clearCategories() {
            this.notSubCategories.forEach(category => {
                if(category.id == this.form.category) this.form.categories.push(category)
            })
        },
        addImage() {
            this.$refs.image.click()
        },
        addImageDrawing() {
            this.$refs.imageDrawing.click()
        },
        addImageWork() {
            this.$refs.imageWork.click()
        },
        setFileImages(e) {

            let files = e.target.files

            for(let i = 0; files.length > i; i++) {
                this.files.push(files[i])

                let reader = new FileReader();
                reader.readAsDataURL(files[i]);
                reader.onload = e => {
                    this.form.images.push({
                        'id': Math.random().toString(16).slice(2),
                        'url': e.target.result
                    })
                }
            }
            
        },
        setImageDrawings(e) {

            let files = e.target.files

            for(let i = 0; files.length > i; i++) {
                this.imageDrawings.push(files[i])

                let reader = new FileReader();
                reader.readAsDataURL(files[i]);
                reader.onload = e => {
                    this.form.drawings.push({
                        'id': Math.random().toString(16).slice(2),
                        'url': e.target.result
                    })
                }
            }
            
        },
        setImageWorks(e) {
            let files = e.target.files

            for(let i = 0; files.length > i; i++) {
                this.imageWorks.push(files[i])

                let reader = new FileReader();
                reader.readAsDataURL(files[i]);
                reader.onload = e => {
                    this.form.works.push({
                        'id': Math.random().toString(16).slice(2),
                        'url': e.target.result
                    })
                }
            }   
        },
        clearFile(id) {
            if(typeof id !== 'string')
                this.removeImages.push(id)

            let images = []

            this.form.images.forEach(image => {
                if(image.id !== id) images.push(image)
            })

            this.form.images = images

            let drawings = []

            this.form.drawings.forEach(drawing => {
                if(drawing.id !== id) drawings.push(drawing)
            })

            this.form.drawings = drawings

            let works = []

            this.form.works.forEach(work => {
                if(work.id !== id) works.push(work)
            })

            this.form.works = works
        },
        submit() {
            this.$refs.createOrUpdateProduct.validate().then(data => {
                if(data.valid) {

                    if(!this.form.data_id) {
                        this.form.import_category_id = false
                        this.form.import_description = false
                        this.form.import_image = false
                        this.form.import_title = false
                    }

                    if(this.id) this.update()
                    else this.create()
                }
            })
            
        },
        create() {
            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.form)) {
                
                if(key == 'price' || key == 'stock_price' || key == 'rating') {
                    if(!value || value == 0){ 
                        data.append(key, '0.00')
                    }else{
                        data.append(key, parseFloat(value).toFixed(2))
                    }
                } else if(key !== 'images' && key !== 'drawings' && key !== 'works') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : (value == 0 ? 0 : ''))
                    }
                }
            }

            for(let i = 0; i < this.files.length; i++){
                let file = this.files[i];
                data.append('images[' + i + ']', file)
            }

            for(let i = 0; i < this.imageDrawings.length; i++){
                let file = this.imageDrawings[i];
                data.append('image_drawings[' + i + ']', file)
            }

            for(let i = 0; i < this.imageWorks.length; i++){
                let file = this.imageWorks[i];
                data.append('image_works[' + i + ']', file)
            }

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/catalog/product/create',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        if(res.data.data.title) this.setTitle(res.data.data.title)

                        this.setField(res.data.data)

                        if(res.data.data.id) this.$router.push({ name: 'product_edit', params: { id: res.data.data.id }})
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateProduct.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        update() {

            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.form)) {
                
                if(key == 'price' || key == 'stock_price' || key == 'rating') {
                    if(!value || value == 0){ 
                        data.append(key, '0.00')
                    }else{
                        data.append(key, parseFloat(value).toFixed(2))
                    }
                } else if(key !== 'images' && key !== 'drawings' && key !== 'works') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : (value == 0 ? 0 : ''))
                    }
                }
            }

            for(let i = 0; i < this.files.length; i++){
                let file = this.files[i];
                data.append('images[' + i + ']', file)
            }

            for(let i = 0; i < this.imageDrawings.length; i++){
                let file = this.imageDrawings[i];
                data.append('image_drawings[' + i + ']', file)
            }

            for(let i = 0; i < this.imageWorks.length; i++){
                let file = this.imageWorks[i];
                data.append('image_works[' + i + ']', file)
            }

            for(let i = 0; i < this.removeImages.length; i++){
                let id = this.removeImages[i];
                data.append('remove_images[' + i + ']', id)
            }


            this.$store.dispatch('API', {
                method: 'POST',
                url: '/catalog/product/' + this.id + '/update',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {

                        if(res.data.data.title) this.setTitle(res.data.data.title)

                        this.setField(res.data.data)

                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateProduct.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        }
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
        categories() {
            return this.$store.state.categoryItem
        },
        notSubCategories() {
            return this.$store.getters.categories
        },
        schema() {
            return yup.object({
                title: yup.string().required().min(2).max(255),
                description: yup.string().nullable(),
                data_id: yup.string().max(255).nullable(),
                data_xml_id: yup.string().max(255).nullable(),
                url: yup.string().max(255).nullable(),

                article: yup.string().max(255).nullable(),
                d_model: yup.string().nullable(),
                video: yup.string().nullable(),
                price: yup.number().min(0.00),
                stock_price: yup.number().min(0.00),
                quantity: yup.number().min(0.00),
                rating: yup.number().min(0.00).max(10.00)
            })
        }
    }
}
</script>