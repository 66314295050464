<template>
    <div class="app-sensor water-leak">
        <div class="app-sensor__head">
            <div class="app-sensor__title">{{ item.name }}</div>
            <div class="app-sensor__action">
                <slot name="actionHeader" />
            </div>
        </div>
        <div class="app-sensor__body">
            <div class="app-sensor__description font-weight-light">
                {{ item.description }}
            </div>
            <div class="app-sensor__value" :class="leak ? 'leak' : 'not-leak'">
                <v-icon v-if="leak">fa fa-droplet</v-icon>
                <v-icon v-else>fa fa-droplet-slash</v-icon>
            </div>
        </div>
        <div class="app-sensor__chart">
            <SensorStatWaterLeak :id="item.id" />
        </div>
    </div>
</template>
<script>

import SensorStatWaterLeak from './SensorStatWaterLeak'

export default {
    name: 'SensorWaterLeak',
    props: {
        sensor: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    components: {
        SensorStatWaterLeak
    },
    data: () => ({

    }),
    mounted () {
    },
    watch: {
    },
    methods: {
    },
    computed: {
        item() {
            return this.sensor
        },
        leak() {
            return this.item.value === 0 ? false : true
        }
    },
    created() {   
    },
    unmounted() {
    }
}
</script>