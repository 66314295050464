<template>
    <v-dialog
        v-model="openDialog"
        persistent
        width="600px"
    >
        <v-card>
            <v-card-title class="text-h5">
                {{ title }}
            </v-card-title>
            <v-card-text>
                <Form
                    @submit.prevent="submit"
                    ref="createOrUpdate"
                    :validation-schema="schema"
                >
                    <Field
                        v-model="name"
                        name="name"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Название"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <div class="mt-3 mb-3">
                        <Field
                            v-model="description"
                            name="description"
                            v-slot="{ field, errors, handleChange }"
                        >
                            <quill-editor
                                v-model:content="field.value"
                                placeholder="Описание" 
                                :enable="!loading"
                                contentType="html"
                                @update:content="handleChange"
                            >
                            </quill-editor>

                            <div v-if="errors.length" class="mt-2 v-messages text-error" style="opacity: 1;" role="alert">
                                <div class="v-messages__message">обязательно для заполнения</div>
                            </div>
                        </Field>
                    </div>

                    <Field
                        v-model="sort"
                        name="sort"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Сортировка"
                            required
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-text-field>
                    </Field>

                    <v-switch
                      v-model="status"
                      :label="status ? 'Включен' : 'Выключен'"
                      color="indigo-darken-3"
                      hide-details
                      :disabled="loading"
                    ></v-switch>
                </Form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    variant="text"
                    @click="close()"
                >
                    Отмена
                </v-btn>
                <v-btn
                    color="green-darken-1"
                    variant="text"
                    :loading="loading"
                    @click="submit"
                >
                    {{ buttonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number,
            default: null
        },
        item: {
            type: Object,
            default: () => {
                return null
            }
        },
    },
    emits: ['close'],
    components: {
        Form,
        Field
    },
    name: 'AddOrEditFaq',
    data: () => ({
        id: null,
        name: null,
        description: null,
        sort: 0,
        status: true,
        openDialog: false,
        loading: false
    }),
    watch: {
        open(value) {

            this.openDialog = value

            this.loading = false

            if(value) {

                this.id = null
                this.name = null
                this.description = null
                this.sort = 0
                this.status = true

                if(this.item != null) {
                    this.id = this.item.id
                    this.name = this.item.name
                    this.description = this.item.description
                    this.sort = this.item.sort
                    this.status = this.item.status == 1 ? true : false
                }
            }else{
                this.$refs.createOrUpdate.resetForm()
            }
        }
    },
    methods: {
        close(save = false) {
            this.$emit('close', {
                index: this.index,
                id: this.id,
                name: this.name,
                description: this.description,
                sort: this.sort,
                status: this.status ? 1 : 0,
                save: save
            })
        },
        submit() {

            this.$refs.createOrUpdate.validate().then(data => {
                if(data.valid) {
                    if(this.item != null && this.item.id) {
                        this.update()
                    }else{
                        this.create()
                    }
                }
            })

            
        },
        create() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/faq/create',
                data: {
                    name: this.name,
                    description: this.description,
                    sort: this.sort,
                    status: this.status ? 1 : 0
                }
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        this.id = res.data.data.id
                        this.close(true)
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdate.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        update() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/faq/' + this.item.id + '/update',
                data: {
                    name: this.name,
                    description: this.description,
                    sort: this.sort,
                    status: this.status ? 1 : 0
                }
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        this.close(true)
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdate.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
    },
    computed: {
        title() {
            return this.name ? 'Редактирование' : 'Добавить'
        },
        buttonText() {
            return this.name ? 'Сохранить' : 'Создать'
        },
        schema() {
            return yup.object({
                name: yup.string().required().min(2).max(255),
                description: yup.string().required(),
                sort: yup.number().nullable(),
            })
        }
    }
}
</script>