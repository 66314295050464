<template>
    <div class="app-setting">
        <v-row
            no-gutters
        >
            <v-col
                md="2"
                sm="12"
            >
                <v-list density="compact">
                    <v-list-item
                        to='/setting/default'
                    >
                        <v-list-item-title>
                            Основные настройки
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        to="/setting/support-contact"
                    >
                        <v-list-item-title>
                            Контакты поддержки
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        to="/setting/about-link"
                    >
                        <v-list-item-title>
                            Ссылки
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        to="/setting/user"
                    >
                        <v-list-item-title>
                            Пользователи
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col
                md="10"
                sm="12"
                class="pl-4 pr-4"
            >
                <router-view />
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: 'SettingPage',
}
</script>