<template>
    <v-container
        fluid
    >
        <v-row
            align="center"
            justify="center"
        >
            <v-col
                cols="12"
                sm="12"
                md="12"
                align="center"
                class="mt-12 mb-6"
            >
                <a href="https://www.ecvols.ru" title="Ecvols"><img height="80" src="~@/assets/logo.svg" alt="Ecvols" /></a>
            </v-col>
            <v-col
                cols="12"
                sm="8"
                md="3"
            >
                <v-card class="elevation-3 pa-4">
                    <router-view/>
                </v-card>
            </v-col>
        </v-row>
        <v-footer class="text-center mt-10">
            <div class="text-center w-100">
                © {{ new Date().getFullYear() }} - <a href="https://www.ecvols.ru" target="_blank" title="Ecvols"><strong>Ecvols</strong></a>
            </div>
        </v-footer>
    </v-container>
</template>

<script>

export default {
    name: 'AuthLayout',

    data: () => ({
        //
    }),
}
</script>
