<template>
    <div class="app-sensor">
        <div class="app-sensor__head">
            <div class="app-sensor__title">{{ item.name }}</div>
            <div class="app-sensor__action">
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            variant="plain"
                            v-bind="props"
                            density="compact"
                        >
                            {{ tempLabel }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item :active="tempValue === 0" @click="setTemp(0)">
                            <v-list-item-title>°C</v-list-item-title>
                        </v-list-item>
                        <v-list-item :active="tempValue === 1" @click="setTemp(1)">
                            <v-list-item-title>°F</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <slot name="actionHeader" />
            </div>
        </div>
        <div class="app-sensor__body">
            <div class="app-sensor__description font-weight-light">
                {{ item.description }}
            </div>
            <div class="app-sensor__value">
                {{ value }} <span>{{ tempLabel}}</span>
            </div>
        </div>
        <div class="app-sensor__chart">
            <SensorStatTemperature :id="item.id" />
        </div>
    </div>
</template>
<script>

import SensorStatTemperature from './SensorStatTemperature'

export default {
    name: 'SensorTemperature',
    props: {
        sensor: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    components: {
        SensorStatTemperature
    },
    data: () => ({

    }),
    mounted () {
    },
    watch: {
    },
    methods: {
        setTemp(val = 0) {
            this.$store.commit('Update', {
                key: 'temperatureValue',
                value: val
            })
        }
    },
    computed: {
        item() {
            return this.sensor
        },
        tempValue() {
            return this.$store.state.temperatureValue
        },
        tempLabel() {
            return this.tempValue === 0 ? '°C' : '°F'
        },
        value() {
            let temp = this.item.value - 273.15

            if(this.tempValue === 1) {
                temp = temp * 1.8 + 32
            }

            return Math.round(temp)
        },
    },
    created() {   
    },
    unmounted() {
    }
}
</script>