<template>
    <div v-if="loading" class="loading">
        <v-progress-circular
            color="indigo-darken-3"
            indeterminate
        ></v-progress-circular>
    </div>
    <v-chart v-else :option="option" />
</template>
<script>

import { use } from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"
import { BarChart } from "echarts/charts"
import {
    TooltipComponent,
    GridComponent,
    VisualMapComponent
} from "echarts/components"
import VChart, { THEME_KEY } from "vue-echarts"

use([
    CanvasRenderer,
    BarChart,
    TooltipComponent,
    GridComponent,
    VisualMapComponent
])

export default {
    name: 'SensorStatWeightSensor',
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    components: {
        VChart
    },
    provide: {
        [THEME_KEY]: "light"
    },
    data: () => ({
        loading: true,
        option: null,
        items: null,
        reset: null,
        days: [
            'Вс',
            'Пн',
            'Вт',
            'Ср',
            'Чт',
            'Пт',
            'Сб'
        ]
    }),
    mounted () {
        this.init()
    },
    watch: {
    },
    methods: {
        init() {
            this.loading = true

            this.get()

            this.reset = setInterval(() => {
                this.get()
            }, 60000)
        },
        get() {
            this.$store.dispatch('API', {
                method: 'GET',
                url: '/device/' + this.$route.params.id + '/stats/sensor/' + this.id
            }).then(res => {

                if(res.data){
                    if(res.data.status === 'success') {

                        this.items = res.data.data

                        this.parseData()

                    }
                }

                this.loading = false
            })
        },
        parseData() {
            let keys = []
            let values = []

            let items = Object.fromEntries(Object.entries(this.items).sort())

            for(let key in items) {
                keys.push(this.days[this.$moment(key).day()])
                values.push({
                    value: Math.round(items[key]),
                    itemStyle: {
                        color: this.setColor(items[key])
                    }
                })
            }

            this.setChart(keys, values)
        },
        setColor(value = 0) {

            if(value >= 40) {
                return '#1B5E20'
            }else if (value < 40 && value >= 30) {
                return '#43A047'
            }else if (value < 30 && value >= 20) {
                return '#FFA726'
            }else if (value < 20 && value >= 10) {
                return '#EF6C00'
            }

            return '#D50000'
        },
        setChart(keys = [], values = []) {
            this.option = {
                grid: {
                    top: '5%',
                    left: '0%',
                    right: '0%',
                    bottom: '20%',
                    containLabel: false
                },
                xAxis: {
                    type: 'category',
                    data: keys,
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    min: 0
                },
                tooltip: {
                    trigger: "axis",
                    formatter: "{c}",
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                series: [
                    {
                        data: values,
                        type: 'bar',
                        smooth: false,
                        layout: 'none',
                        showSymbol: true,
                        itemStyle: {
                            color: '#3196FA'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            color: '#fff'
                        },
                    }
                ],
            }
        },
    },
    computed: {
    },
    created() {   
    },
    unmounted() {
        clearInterval(this.reset)
        this.reset = null
    },
}
</script>