<template>
    <div class="app-catalog">
        <div class="app-catalog__category">
            <v-row 
                no-gutters
            >
                <v-col 
                    sm="12"
                    md="6"
                >

                    <div class="d-flex w-100 mb-3">
                        <h1 class="text-h4">{{ titleHeading }}</h1>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="success"
                            @click="addOrEdit()"
                            :disabled="loading"
                        >
                            Добавить FAQ
                        </v-btn>
                    </div>
                    <v-skeleton-loader
                        :loading="loading"
                        type="paragraph@3"
                        class="w-100"
                    >
                        <v-expansion-panels class="mb-6">

                            <v-expansion-panel v-for="(item, i) in items" :key="i">
                                <v-expansion-panel-title>
                                    {{ item.name }}
                                    <template v-slot:actions="{ expanded }">
                                        <v-icon size="14" :icon="expanded ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"></v-icon>
                                    </template>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>

                                    <p v-html="item.description"></p>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            variant="text"
                                            color="warning"
                                            @click="remove(i, item)"
                                            :loading="loadingItem[i] === true"
                                        >
                                            Удалить
                                        </v-btn>
                                        <v-btn
                                            variant="text"
                                            @click="addOrEdit(i, item)"
                                        >
                                            Изменить
                                        </v-btn>
                                    </v-card-actions>
                                </v-expansion-panel-text>
                            </v-expansion-panel>

                        </v-expansion-panels>
                    </v-skeleton-loader>
                </v-col>
            </v-row>
        </div>

        <AddOrEditFaq :open="openDialog" :item="itemEdit" :index="indexEdit" @close="closeDialog" />

        <RemovePopup
            :data="removeData"
            :open="openRemove"
            :title="titleRemove"
            :body="bodyRemove"
            @close="closeRemove"
            @action="removeItem"
            :loading="loadingRemove" />
    </div>
</template>
<script>
import AddOrEditFaq from '@/components/popus/AddOrEditFaq'
import RemovePopup from '@/components/popus/RemovePopup'

export default {
    name: 'FaqPage',
    components: {
        AddOrEditFaq,
        RemovePopup
    },
    data: () => ({
        title: 'FAQ',
        openDialog: false,
        itemEdit: null,
        indexEdit: null,
        loading: false,
        items: [],
        loadingItem: {},
        openRemove: false,
        removeData: {},
        loadingRemove: false,
        titleRemove: '',
        bodyRemove: ''
    }),
    mounted () {
        this.init()
    },
    methods: {
        init() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/faq',
            }).then(res => {

                if(res.data.status === 'success') {
                    this.items = res.data.data
                }

                this.loading = false
            })
        },
        addOrEdit(index = null, item = null) {
            this.openDialog = true
            this.itemEdit = item
            this.indexEdit = index
        },
        closeDialog(data) {
            this.openDialog = false
            if(data.save) {
                if(data.index !== null) {
                    this.items[data.index] = {
                        id: data.id,
                        name: data.name,
                        description: data.description,
                        sort: data.sort,
                        status: data.status
                    }
                }else{

                    let newItems = [{
                        id: data.id,
                        name: data.name,
                        description: data.description,
                        sort: data.sort,
                        status: data.status
                    }]

                    this.items = newItems.concat(this.items)
                }
            }
        },
        remove(i, item) {

            this.removeData = {
                key: i,
                item: item
            }

            this.openRemove = true
            this.titleRemove = 'Удалить FAQ #' + item.id
            this.bodyRemove = 'Вы уверены, что хотите удалить FAQ <b>' + item.name + '</b>?'

        },
        closeRemove() {
            this.openRemove = false
        },
        removeItem(data) {

            this.loadingRemove = true
            this.loadingItem[data.key] = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/faq/' + data.item.id + '/remove',
            }).then(res => {

                if(res.data.status === 'success') {
                    // delete this.items[data.key]

                    let items = []

                    this.items.forEach((item, i) => {
                        if(i != data.key) items.push(item)
                    })

                    this.items = items
                }

                delete this.loadingItem[data.key]

                this.loadingRemove = false
                this.openRemove = false

                this.removeData = {}
            })
        }
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
    }
}
</script>