<template>
    <div class="app-control">
        <div class="app-control__head">
            <div class="app-control__title">{{ item.name }}</div>
            <div class="app-control__action">

                <v-btn
                    :color="option.auto === 0 ? 'error' : 'success'"
                    density="compact"
                    variant="text"
                    :disabled="loadingValue"
                    @click="setValue(option.auto === 0 ? 1 : 0, 'auto')"
                    :loading="loadingAuto"
                >
                    auto
                </v-btn>

                <v-btn
                    :color="item.value === 1 ? 'error' : 'success'"
                    icon
                    variant="text"
                    density="compact"
                    :disabled="option.auto === 1 || loadingAuto ? true : false"
                    @click="setValue(item.value === 0 ? 1 : 0)"
                    :loading="loadingValue"
                >
                    <v-icon size="15">fa fa-power-off</v-icon>
                </v-btn>

                <slot name="actionHeader" />
            </div>
        </div>
        <div class="app-control__body">
            <div class="app-control__description font-weight-light">
                {{ item.description }}
            </div>
        </div>
    </div>
</template>
<script>


export default {
    name: 'ControlRelay',
    emits: ['update'],
    props: {
        control: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    components: {

    },
    data: () => ({
        loadingAuto: false,
        loadingValue: false
    }),
    mounted () {
    },
    watch: {
    },
    methods: {
        setValue(val = 0, type = 'value') {
            
            if (type === 'value') {
                this.loadingValue = true
            }

            if (type === 'auto') {
                this.loadingAuto = true
            }

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/device/' + this.$route.params.id + '/stats/control/' + this.item.id + '/value',
                data: {
                    type: type,
                    value: val
                }
            }).then(res => {

                if(res && res.data){
                    if(res.data.status === 'success') {
                        this.$emit('update', res.data.data)
                    }
                }

                this.loadingAuto = false
                this.loadingValue = false
            })
        },
    },
    computed: {
        item() {
            return this.control
        },
        option() {

            let data = {
                auto: 0
            }

            if(this.item.options) {
                data = this.item.options
            }

            return data
        }
    },
    created() {   
    },
    unmounted() {
    }
}
</script>