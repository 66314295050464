<template>
    <v-dialog
        v-model="open"
        persistent
        max-width="600"
    >
        <v-card
        >
            <v-skeleton-loader
                :loading="getLoading"
                type="subtitle, list-item@5"
                class="w-100 d-block"
            >
                <v-card-title>
                    {{ item.name }}
                </v-card-title>

                <v-card-subtitle v-if="typeName">
                    {{ typeName }}
                </v-card-subtitle>

                <v-card-item>
                    <Form
                        @submit.prevent="submit"
                        ref="updateForm"
                        :validation-schema="schema"
                    >

                        <v-text-field
                            v-model="item.control_id"
                            label="ID контроллера"
                            color="indigo-darken-3"
                            variant="underlined"
                            disabled
                        />

                        <Field
                            v-model="item.name"
                            name="name"
                            v-slot="{ field, errors }"  
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                label="Название"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                :disabled="loading"
                            />
                        </Field>

                        <Field
                            v-model="item.description"
                            name="description"
                            v-slot="{ field, errors }"  
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                label="Описание"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                :disabled="loading"
                            />
                        </Field>
                    </Form>
                </v-card-item>
            </v-skeleton-loader>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-3"
                    text="Закрыть"
                    @click="open = false"
                ></v-btn>
                <v-btn
                    color="success"
                    text="Сохранить"
                    @click="submit"
                    :disabled="getLoading"
                    :loading="loading"
                ></v-btn>
            </template>
        </v-card>
    </v-dialog>
</template>
<script>
import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'SettingControlPopup',
    emits: ['close', 'update'],
    props: {
        control_id: {
            type: Number,
            default: null
        }
    },
    components: {
        Form,
        Field
    },
    data: () => ({
        open: false,
        item: {},
        getLoading: false,
        loading: false,
    }),
    mounted () {
    },
    watch: {
        control_id(value) {
            if(value) {
                this.open = true
            }else{
                this.open = false
            }
        },
        open(status) {
            if(status) {
                this.init()
            }else{
                this.$emit('close')
                this.item = {}
                this.$refs.updateForm.resetForm()
            }
        }
    },
    methods: {
        init() {
            this.getLoading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/device/' + this.$route.params.id + '/stats/control/' + this.control_id + '/setting'
            }).then(res => {

                if(res.data){
                    if(res.data.status === 'success') {
                        this.item = res.data.data
                    }
                }

                this.getLoading = false
            })
        },
        submit() {
            this.$refs.updateForm.validate().then(data => {
                if(data.valid) {

                    this.loading = true

                    this.$store.dispatch('API', {
                        method: 'POST',
                        url: '/device/' + this.$route.params.id + '/stats/control/' + this.control_id + '/setting',
                        data: this.item
                    }).then(res => {
                        if(res.data) {
                            if(res.data.status === 'success') {
                                this.item = res.data.data
                                this.$emit('update', this.item)
                            }
                        } else if(res.response && res.response.status == 422) {
                            if(res.response.data.errors) {

                                this.$refs.updateForm.setErrors(res.response.data.errors)
                            }
                        }

                        this.loading = false
                    })
                }
            })
        }
    },
    computed: {
        schema() {
            return yup.object({
                name: yup.string().required().min(2).max(255),
                description: yup.string().nullable().max(1000),
            })
        },
        typeName() {

            let name = ''

            switch(this.item.type) {
                case 0:
                    name = 'Реле'
                    break
            }

            return name
        }
    },
    created() {   
    },
    unmounted() {
        this.open = false
    }
}
</script>