<template>
    <h1 class="text-h4 mb-1">{{ titleHeading }}</h1>

    <div class="app-catalog__category">
        <Form
            @submit.prevent="submit"
            ref="createOrUpdateCategory"
            :validation-schema="schema"
        >
            <v-row>
                <v-col sm="12" md="8">
                    <div v-if="fields.image" class="d-block mb-6">
                        <v-hover>
                            <template v-slot:default="{ isHovering, props }">
                                <v-img
                                    v-bind="props"
                                    :lazy-src="fields.image"
                                    max-height="200"
                                    :src="fields.image"
                                    class="elevation-4"
                                >
                                    <span
                                        v-show="isHovering"
                                        style="position: absolute; top: 0.8rem; right: 0.8rem;"
                                    >
                                        <v-btn
                                            v-if="!file"
                                            color="black"
                                            right
                                            size="small"
                                            icon
                                            variant="text"
                                            :href="fields.image"
                                            target="_blank"
                                        >
                                            <v-icon size="14">fa fa-arrow-up-right-from-square</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-show="isHovering"
                                            color="red"
                                            size="small"
                                            icon
                                            variant="text"
                                            @click="clearFile"
                                        >
                                            <v-icon size="14">fa fa-trash</v-icon>
                                        </v-btn>
                                    </span>
                                </v-img>
                            </template>
                        </v-hover>
                    </div>

                    <div v-else class="d-flex w-100 mb-4">
                        <input ref="image" type="file" value="" class="d-none" accept="image/*" @change="setFile" />
                        <v-btn
                            color="indigo-darken-3"
                            variant="outlined"
                            block
                            @click="addImage"
                        >
                            <template v-slot:prepend>
                                <v-icon size="14">fa fa-plus</v-icon>
                            </template>
                            Добавить изображение
                        </v-btn>
                    </div>

                    <Field
                        v-model="fields.title"
                        name="title"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Название"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <div class="mb-3">
                        <quill-editor
                            v-model:content="fields.description"
                            placeholder="Описание" 
                            :enable="!loading"
                            contentType="html"
                            toolbar="full"
                        >
                        </quill-editor>
                    </div>

                    <!-- <Field
                        v-model="fields.description"
                        name="description"
                        v-slot="{ field, errors }"  
                    >
                        <v-textarea
                            v-bind="field"
                            :model-value="field.value"
                            label="Описание"
                            auto-grow
                            color="indigo-darken-3"
                            variant="underlined"
                            rows="1"
                            row-height="15"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-textarea>
                    </Field> -->

                    <Field
                        v-model="fields.data_id"
                        name="data_id"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="ID на сайте Экволс"
                            color="indigo-darken-3"
                            variant="underlined"
                            :disabled="loading"
                            :error-messages="errors"
                        />
                    </Field>

                    <Field
                        v-model="fields.data_xml_id"
                        name="data_xml_id"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="XML code"
                            color="indigo-darken-3"
                            variant="underlined"
                            :disabled="loading"
                            :error-messages="errors"
                        />
                    </Field>

                    <Field
                        v-model="fields.url"
                        name="url"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Ссылка на товар (www.ecvols.ru)"
                            color="indigo-darken-3"
                            variant="underlined"
                            :disabled="loading"
                            :error-messages="errors"
                        />
                    </Field>

                    <Field
                        v-model="fields.sort"
                        name="sort"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Сортировка"
                            required
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-text-field>
                    </Field>

                    <v-switch
                      v-model="fields.status"
                      :label="fields.status ? 'Акция включена' : 'Акция выключена'"
                      color="indigo-darken-3"
                      hide-details
                      :disabled="loading"
                    ></v-switch>
                </v-col>

                <v-col sm="12" md="4">
                    <div class="text-h5">Настройки импорта акции</div>

                    <v-switch
                        v-model="fields.import_image"
                        label="Не обновлять изображение"
                        color="indigo-darken-3"
                        hide-details
                        :disabled="loading || (!loading && !fields.data_id)"
                    ></v-switch>

                    <v-switch
                        v-model="fields.import_title"
                        label="Не обновлять название"
                        color="indigo-darken-3"
                        hide-details
                        :disabled="loading || (!loading && !fields.data_id)"
                    ></v-switch>

                    <v-switch
                        v-model="fields.import_description"
                        label="Не обновлять описание"
                        color="indigo-darken-3"
                        hide-details
                        :disabled="loading || (!loading && !fields.data_id)"
                    ></v-switch>
                </v-col>
            

                <v-col sm="12">
                    <div class="d-flex justify-space-between">
                        <v-btn
                            @click="$router.go(-1)"
                        >
                            Назад
                        </v-btn>
                        <v-btn
                            color="success"
                            :loading="loading"
                            @click="submit"
                        >
                            Сохранить
                        </v-btn>
                    </div>
                </v-col>

            </v-row>
        </Form>
    </div>
</template>
<script>

import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'StockEditPage',
    components: {
        Form,
        Field
    },
    data: () => ({
        title: '',
        id: null,
        file: null,
        fields: {
            'title': null,
            'import_title': false,
            'description': null,
            'import_description': false,
            'data_id': null,
            'data_xml_id': null,
            'image': null,
            'import_image': false,
            'sort': 0,
            'url': null,
            'status': true,
        },
        loading: false,
    }),
    mounted () {
        if(this.$route.params.id) this.id = this.$route.params.id
    },
    watch: {
        id() {
            this.init()
        }
    },
    methods: {
        init() {
            this.get()
        },
        get() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/stock/' + this.id + '/edit',
            }).then(res => {

                if(res.data.status === 'success') {

                    if(res.data.data.title) this.setTitle(res.data.data.title)

                    this.setField(res.data.data)

                }

                this.loading = false

            })
        },
        setTitle(title = '') {
            if(title) {
                this.$store.commit('Update', {
                    key: 'titlePage',
                    value: title
                })

                this.title = title
            }
        },
        setField(fields) {
            for (let key in fields) {
                if(this.fields[key] !== undefined) {
                    if (key == 'status' || key == 'import_image' || key == 'import_title' || key == 'import_description')
                        this.fields[key] = fields[key] == 1 ? true : false
                    else
                        this.fields[key] = fields[key]
                }
            }
        },
        addImage() {
            this.$refs.image.click()
        },
        setFile(e) {
            if(e.target.files[0]) {
                this.file = e.target.files[0]
                
                let reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onload = e => {
                    this.fields.image = e.target.result
                }
            }
        },
        clearFile() {
            this.file = null
            this.fields.image = null
        },
        submit() {
            this.$refs.createOrUpdateCategory.validate().then(data => {
                if(data.valid) {

                    if(!this.fields.data_id) {
                        this.fields.import_description = false
                        this.fields.import_image = false
                        this.fields.import_title = false
                    }

                    if(this.id) this.update()
                    else this.create()
                }
            })
            
        },
        create() {
            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key !== 'image') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : (value == 0 ? 0 : ''))
                    }
                }
            }

            if (this.file)
                data.append('image', this.file)


            this.$store.dispatch('API', {
                method: 'POST',
                url: '/stock/create',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        if(res.data.data.title) this.setTitle(res.data.data.title)

                        this.setField(res.data.data)

                        if(res.data.data.id) this.$router.push({ name: 'stock_edit', params: { id: res.data.data.id }})
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateCategory.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        update() {

            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key !== 'image') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : (value == 0 ? 0 : ''))
                    }
                }
            }

            if (this.file)
                data.append('image', this.file)

            if(!this.file && !this.fields.image)
                data.append('remove_image', 1)


            this.$store.dispatch('API', {
                method: 'POST',
                url: '/stock/' + this.id + '/update',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {

                        if(res.data.data.title) this.setTitle(res.data.data.title)

                        this.setField(res.data.data)

                        this.file = null
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateCategory.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        }
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
        schema() {
            return yup.object({
                title: yup.string().required().min(2).max(255),
                description: yup.string().nullable(),
                sort: yup.number().nullable(),
                data_id: yup.string().max(255).nullable(),
                data_xml_id: yup.string().max(255).nullable(),
                url: yup.string().max(255).nullable(),
            })
        }
    }
}
</script>