<template>
    <div class="app-sensor water-leak">
        <div class="app-sensor__head">
            <div class="app-sensor__title">{{ item.name }}</div>
            <div class="app-sensor__action">
                <slot name="actionHeader" />
            </div>
        </div>
        <div class="app-sensor__body">
            <div class="app-sensor__description font-weight-light">
                {{ item.description }}
            </div>
            <div class="app-sensor__value">
                {{ value }} <span>кг</span>
            </div>
        </div>
        <div class="app-sensor__chart">
            <SensorStatWeightSensor :id="item.id" />
        </div>
    </div>
</template>
<script>

import SensorStatWeightSensor from './SensorStatWeightSensor'

export default {
    name: 'SensorWeight',
    props: {
        sensor: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    components: {
        SensorStatWeightSensor
    },
    data: () => ({

    }),
    mounted () {
    },
    watch: {
    },
    methods: {
    },
    computed: {
        item() {
            return this.sensor
        },
        value() {
            return Math.round(this.item.value)
        }
    },
    created() {   
    },
    unmounted() {
    }
}
</script>