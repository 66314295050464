<template>
    <v-row
        no-gutters
    >
        <v-col
            md="6"
            sm="12"
        >

            <h1 class="text-h4 mb-3">Контакты поддержки</h1>

            <Form
                @submit.prevent="submit"
                ref="settingForm"
                :validation-schema="schema"
            >

                <div v-for="(field, i) in fields" :key="i">
                    <v-row
                        class="align-center"
                    >
                        <v-col sm="4">
                            <Field
                                v-model="fields[i].title"
                                :name="'fields.' + i + '.title'"
                                v-slot="{ field, errors }"  
                            >
                                <v-text-field
                                    v-bind="field"
                                    :model-value="field.value"
                                    label="Заголовок"
                                    color="indigo-darken-3"
                                    variant="underlined"
                                    :error-messages="errors"
                                    required
                                    :disabled="loading"
                                >
                                </v-text-field>
                            </Field>
                        </v-col>
                        <v-col sm="6">
                            <Field
                                v-model="fields[i].url"
                                :name="'fields.' + i + '.url'"
                                v-slot="{ field, errors }"  
                            >
                                <v-text-field
                                    v-bind="field"
                                    :model-value="field.value"
                                    label="Ссылка"
                                    color="indigo-darken-3"
                                    variant="underlined"
                                    :error-messages="errors"
                                    required
                                    :disabled="loading"
                                >
                                </v-text-field>
                            </Field>
                        </v-col>
                        <v-col sm="2">
                            <v-btn
                                block
                                color="error"
                                :disabled="loading || fields.length === 1"
                                @click="removeField(i)"
                                density="compact"
                            >
                                Удалить
                            </v-btn>
                        </v-col>
                    </v-row>                  
                </div>

                <div class="d-flex justify-space-between mt-3 mb-3">
                    <v-btn
                        block
                        variant="tonal"
                        :disabled="loading"
                        @click="addField"
                        density="compact"
                    >
                        Добавить ссылку
                    </v-btn>
                </div>

                <div class="d-flex justify-space-between">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        :loading="loading"
                        @click="submit"
                    >
                        Сохранить
                    </v-btn>
                </div>
            </Form>
        </v-col>
    </v-row>
</template>
<script>
import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'AboutLinkPage',
    components: {
        Form,
        Field
    },
    data: () => ({
        fields: [],
        loading: false,
    }),
    mounted () {
        this.init()
    },
    methods: {
        init() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/setting/about-link',
            }).then(res => {

                if(res.data.status === 'success') {
                    this.setField(res.data.data)
                }

                this.loading = false

            })
        },
        setField(fields) {
            if(fields.length)
                this.fields = fields
            else this.fields.push({title: null, url: null})
        },
        submit() {

            this.$refs.settingForm.validate().then(data => {
                if(data.valid) {

                    this.save()
                }
            })
        },
        save() {

            this.loading = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/setting/about-link/save',
                data: {
                    'fields': this.fields
                }
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        this.setField(res.data.data)
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.settingForm.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        addField() {
            this.fields.push({title: null, url: null})
        },
        removeField(index) {
            this.fields = this.fields.filter((item, i) => i !== index)
        }
    },
    computed: {
        schema() {
            return yup.object({})
        }
    }
}
</script>