<template>
    <v-dialog
        v-model="openDialog"
        persistent
        width="600px"
    >
        <v-card>
            <v-card-title class="text-h5">
                Добавить новый SmartBox
            </v-card-title>
            <v-card-text>
                <Form
                    @submit.prevent="submit"
                    ref="create"
                    :validation-schema="schema"
                >
                    <Field
                        v-model="value.model_id"
                        name="model_id"
                        v-slot="{ field, errors }"  
                    >
                        <v-select
                            v-bind="field"
                            :model-value="field.value"
                            label="Модель устройства"
                            :items="models"
                            item-title="name"
                            item-value="id"
                            variant="underlined"
                            color="indigo-darken-3"
                            menu-icon="fa fa-angle-down"
                            :disabled="loading"
                            :error-messages="errors"
                        >
                            <template v-slot:item="{ props, item }">
                                <v-list-item
                                    v-bind="props"
                                    :title="item.raw.name"
                                ></v-list-item>
                            </template>
                        </v-select>
                    </Field>

                    <div v-if="value.model_id">
                        <h3>Основные</h3>

                        <Field
                            v-model="value.firmware_id"
                            name="firmware_id"
                            v-slot="{ field, errors }"  
                        >
                            <v-select
                                v-bind="field"
                                :model-value="field.value"
                                label="Версия прошивки"
                                :items="firmwares"
                                item-title="version_name"
                                item-value="id"
                                variant="underlined"
                                color="indigo-darken-3"
                                menu-icon="fa fa-angle-down"
                                :disabled="loading"
                                :error-messages="errors"
                            >
                                <template v-slot:item="{ props, item }">
                                    <v-list-item
                                        v-bind="props"
                                        :title="item.raw.version_name + ` (` + item.raw.version + `)`"
                                    ></v-list-item>
                                </template>
                            </v-select>
                        </Field>

                        <Field
                            v-model="value.machine_id"
                            name="machine_id"
                            v-slot="{ field, errors }"  
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                label="Внутренний ID"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                required
                                :disabled="loading"
                            />
                        </Field>

                        <Field
                            v-model="value.name"
                            name="name"
                            v-slot="{ field, errors }"  
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                label="Название"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                required
                                :disabled="loading"
                            />
                        </Field>

                        <Field
                            v-model="value.description"
                            name="description"
                            v-slot="{ field, errors }"  
                        >
                            <v-textarea
                                v-bind="field"
                                :model-value="field.value"
                                label="Описание"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                required
                                :disabled="loading"
                                row-height="5"
                                rows="1"
                                auto-grow
                            />
                        </Field>

                        <h3>Wi-Fi</h3>

                        <Field
                            v-model="value.ap_wifi_password"
                            name="ap_wifi_password"
                            v-slot="{ field, errors }"  
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                label="Пароль от Wi-Fi (local server)"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                required
                                :disabled="loading"
                                append-icon="fas fa-rotate-right"
                                @click:append="generateValue('ap_wifi_password', 8)"
                            />
                        </Field>

                        <h3>Пользователь по-умолчанию</h3>

                        <Field
                            v-model="value.device_user"
                            name="device_user"
                            v-slot="{ field, errors }"  
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                label="Пользователь"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                required
                                :disabled="loading"
                            />
                        </Field>

                        <Field
                            v-model="value.device_password"
                            name="device_password"
                            v-slot="{ field, errors }"  
                        >
                            <v-text-field
                                v-bind="field"
                                :model-value="field.value"
                                label="Пароль"
                                color="indigo-darken-3"
                                variant="underlined"
                                :error-messages="errors"
                                required
                                :disabled="loading"
                                append-icon="fas fa-rotate-right"
                                @click:append="generateValue('device_password', 8)"
                            />
                        </Field>
                    </div>

                </Form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    variant="text"
                    @click="close()"
                >
                    Отмена
                </v-btn>
                <v-btn
                    color="green-darken-1"
                    variant="text"
                    :loading="loading"
                    @click="submit"
                >
                    Создать
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

const fields = {
    'model_id': null,
    'firmware_id': null,
    'machine_id': null,
    'name': null,
    'description': null,
    'ap_wifi_password': null,
    'device_user': null,
    'device_password': null,
}

export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
    },
    emits: ['close'],
    components: {
        Form,
        Field
    },
    name: 'AddDevicePopup',
    data: () => ({
        models: [],
        value: {},
        openDialog: false,
        loading: false
    }),
    watch: {
        open(value) {
            this.openDialog = value

            this.value = fields

            if(value) {
                this.init()
            }else{
                this.$refs.create.resetForm()
            }
        }
    },
    methods: {
        init() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/device/list',
                params: {}
            }).then(res => {

                if(res.data){
                    if(res.data.status === 'success') {
                        this.models = res.data.data
                    }
                }

                this.loading = false

                this.$store.commit('Update', {
                    key: 'preloader',
                    value: false
                })
            })
        },
        close(item = null) {
            this.$emit('close', {
                item: item,
            })
        },
        submit() {
            this.$refs.create.validate().then(data => {
                if(data.valid) {
                    // this.create()
                    this.loading = true

                    let data = new FormData()

                    for (let [key, value] of Object.entries(this.value)) {

                        if(value === true || value === false) {
                            data.append(key, value ? 1 : 0)
                        }else{
                            data.append(key, value ? value : (value == 0 ? 0 : ''))
                        }
                    }

                    this.$store.dispatch('API', {
                        method: 'POST',
                        url: '/device/create',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        data: data
                    }).then(res => {
                        if(res.data) {
                            if(res.data.status === 'success') {
                                this.close(res.data.data)
                            }
                        } else if(res.response && res.response.status == 422) {
                            if(res.response.data.errors) {
                                this.$refs.create.setErrors(res.response.data.errors)
                            }
                        }

                        this.loading = false
                    })
                }
            })
        },
        generateValue(key, length = 16) {
            let string = '';

            const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                string += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            this.value[key] = string
        }
    },
    computed: {
        schema() {

            let validate = {}

            validate.model_id = yup.string().required()
            validate.firmware_id = yup.string().required()
            validate.machine_id = yup.string().required().min(6).max(255)
            validate.name = yup.string().min(2).max(255).required()
            validate.description = yup.string().nullable().min(2).max(1000)
            validate.ap_wifi_password = yup.string().required().min(8).max(32)
            validate.device_user = yup.string().min(2).max(64).required()
            validate.device_password = yup.string().min(6).max(64).required()

            return yup.object(validate)
        },
        firmwares() {

            let items = []

            if(this.value.model_id) {
                this.models.forEach((item) => {
                    if(item.id == this.value.model_id) {

                        this.value.name = item.name

                        this.generateValue('ap_wifi_password', 8)

                        this.value.device_user = 'admin'
                        this.generateValue('device_password', 6)

                        if(item.firmwares.length && item.firmwares[0] && item.firmwares[0].id) this.value.firmware_id = item.firmwares[0].id

                        items = item.firmwares
                    }
                })
            }

            return items
        }
    }
}
</script>